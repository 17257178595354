import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import { faEllipsisV, faSearch, faCopy, faStar, faPlus, faPenToSquare, faTrashCan, faTimes, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { motion, AnimatePresence } from 'framer-motion';

interface Persona {
    id: string;
    name: string;
    personatext: string;
    standard?: boolean;
}

interface WelcomeViewProps {
    personas: Persona[];
    editPersonaById: (id: string) => (event: React.MouseEvent) => void;
    deletePersonaById: (id: string) => (event: React.MouseEvent) => void;
    setShowPersonaForm: (show: boolean) => void;
    handleDuplicatePersona: (persona: Persona) => void;
    handleSetDefault: (id: string) => void;
    creations: number | null;
}

const WelcomeView: React.FC<WelcomeViewProps> = ({
    personas,
    editPersonaById,
    deletePersonaById,
    setShowPersonaForm,
    handleDuplicatePersona,
    handleSetDefault,
    creations
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    const filteredPersonas = personas.filter(persona =>
        persona.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        persona.personatext.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggleExpand = (id: string) => {
        setExpandedItems(prev =>
            prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
        );
    };

    const truncateText = (text: string, lines: number) => {
        const truncated = text.split('\n').slice(0, lines).join('\n');
        return truncated + (text.split('\n').length > lines ? '...' : '');
    };

    return (
        <div className="bg-white md:w-full md:h-screen">
            <div className="mx-auto items-center justify-center p-6 sm:px-[52px] pt-8 !pb-6 h-screen">
                <div className='flex justify-between items-center mb-4'>
                    <h3 className="text-[24px] sm:text-[32px] font-outfit font-semibold text-textColor">Tonfall</h3>
                    <button
                        className={`flex items-center justify-center p-2 sm:p-[10px] rounded-[4px] font-medium ${!creations ? 'bg-gray-300 cursor-not-allowed' : 'bg-secondary hover:bg-secondary-600'} text-white text-xs sm:text-[14px]`}
                        onClick={() => setShowPersonaForm(true)}
                        disabled={!creations}
                    >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        Neuen Tonfall erstellen
                    </button>
                </div>
                {personas.length === 0 ? (
                    <div className="">
                        <p className="mt-3 text-base font-normal leading-[27px] tracking-[0%] text-left text-[#202356b3] mb-4">
                            Erstelle verschiedene Tonfälle - und sorge so für Konsistenz in Deinen KI-generierten Texten.
                        </p>
                        <div className="relative pb-[60%] md:pb-[40%] h-0 overflow-hidden mx-auto mb-4 mt-24" style={{ maxWidth: '750px' }}>
                            <iframe
                                src="https://player.vimeo.com/video/1016438323"
                                className="absolute top-0 left-0 w-full h-full"
                                allow="autoplay; fullscreen; picture-in-picture"
                                title='Jakob erklärt: Tonfall Feature'
                                allowFullScreen
                            >
                            </iframe>
                        </div>
                    </div>
                ) : (
                    <>
                        <p className="text-gray-600 mb-4">
                            Erstelle verschiedene Tonfälle - und sorge so für Konsistenz in Deinen KI-generierten Texten.
                        </p>
                        <div className="relative mb-4">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Tonfall finden"
                                    className="py-2 pl-10 min-w-[300px] border border-neutral-100 rounded-[8px] w-full sm:w-fit text-base focus:outline-none focus:ring-1 focus:ring-purple-700 focus:border-0 pr-10"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                {searchTerm && (
                                    <button
                                        className="absolute left-[90%] sm:left-[275px] top-1/2 transform -translate-y-1/2 text-gray-400 focus:outline-none"
                                        onClick={() => setSearchTerm('')}
                                        style={{
                                            pointerEvents: 'auto',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                )}
                            </div>
                            <FontAwesomeIcon
                                icon={faSearch}
                                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                            />
                        </div>
                        {filteredPersonas.length > 0 ? (
                            <div className="bg-white rounded-lg">
                                <div className="flex justify-between items-center p-4 font-semibold">
                                    <div className="w-[20%] sm:w-[30%] md:w-[20%]">Name</div>
                                    <div className="w-[60%] sm:w-[65%] md:w-[70%]">Tonfall</div>
                                    <div className="w-[20%] sm:w-[5%] md:w-[10%] text-right">Aktionen</div>
                                </div>
                                <div className="rounded-[8px] border border-neutral-100 max-h-[60vh] overflow-y-auto">
                                    {filteredPersonas.map((item, index) => (
                                        <div
                                            key={index}
                                            className={`relative flex flex-col sm:flex-row items-start py-3.5 px-[28px] lg:px-12 ${item.standard ? 'bg-[#9C68EF0D]' : 'bg-white'}`}
                                        >
                                            <div className="w-full sm:w-[30%] md:w-[20%] flex items-center mb-2 sm:mb-0">
                                                    {item.standard && (
                                                    <div className="flex items-center justify-center min-w-[20px] mr-2">
                                                        <FontAwesomeIcon icon={faStar} className="text-yellow-500 text-sm" />
                                                    </div>
                                                    )}
                                                <button
                                                    onClick={() => toggleExpand(item.id)}
                                                    className="mr-2 focus:outline-none"
                                                    data-testid="tonfall-expand-button"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={expandedItems.includes(item.id) ? faChevronDown : faChevronRight}
                                                        className="text-gray-500"
                                                    />
                                                </button>
                                                <p data-testid="tonfall-name" className="w-full pe-2 whitespace-pre-wrap break-words text-xs sm:text-sm lg:text-base font-medium">{item.name}</p>
                                            </div>
                                            <div className="w-full sm:w-[65%] md:w-[70%] text-gray-600 whitespace-normal break-words text-xs lg:text-sm pl-[32px] sm:pl-0">
                                                <div className='w-full prose prose-sm'>
                                                    <AnimatePresence>
                                                        <motion.div
                                                            initial={{ height: 0 }}
                                                            animate={{ height: expandedItems.includes(item.id) ? 'auto' : 'fit-content' }}
                                                            exit={{ height: 0 }}
                                                            transition={{ duration: 0.3 }}
                                                            style={{ overflow: 'hidden' }}
                                                        >
                                                            <ReactMarkdown data-testid="tonfall-text">
                                                                {expandedItems.includes(item.id)
                                                                    ? item.personatext
                                                                    : truncateText(item.personatext, 1)}
                                                            </ReactMarkdown>
                                                        </motion.div>
                                                    </AnimatePresence>
                                                </div>
                                            </div>
                                            <div className="w-full sm:w-[5%] md:w-[10%] flex items-center justify-end mt-2 sm:mt-0 absolute top-1/2 right-4 sm:relative sm:top-auto sm:right-auto transform -translate-y-1/2 sm:transform-none">
                                                <DropdownMenu.Root>
                                                    <DropdownMenu.Trigger asChild>
                                                        <button data-testid="tonfall-dropdown-button" className="flex items-center justify-center p-2 rounded-[8px] hover:bg-gray-100 focus:outline-none">
                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                        </button>
                                                    </DropdownMenu.Trigger>

                                                    <DropdownMenu.Portal>
                                                        <DropdownMenu.Content
                                                            className="bg-white border border-neutral-100 rounded-[8px] shadow-lg p-1 z-50"
                                                            sideOffset={5}
                                                        >
                                                            <DropdownMenu.Item
                                                                className="flex items-center px-4 py-2 cursor-pointer rounded-[8px] border-0 hover:bg-gray-100 transition-colors duration-200 focus:outline-none"
                                                                onClick={editPersonaById(item.id)}
                                                                data-testid="tonfall-dropdown-edit"
                                                            >
                                                                <FontAwesomeIcon icon={faPenToSquare} className="mr-2 text-gray-500" />
                                                                Bearbeiten
                                                            </DropdownMenu.Item>
                                                            <DropdownMenu.Item
                                                                className="flex items-center px-4 py-2 cursor-pointer rounded-[8px] border-0 hover:bg-gray-100 transition-colors duration-200 focus:outline-none"
                                                                onClick={deletePersonaById(item.id)}
                                                                data-testid="tonfall-dropdown-delete"
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} className="mr-2 text-gray-500" />
                                                                Löschen
                                                            </DropdownMenu.Item>
                                                            <DropdownMenu.Item
                                                                className="flex items-center px-4 py-2 cursor-pointer rounded-[8px] hover:bg-gray-100 transition-colors duration-200 focus:outline-none"
                                                                onClick={() => handleDuplicatePersona(item)}
                                                                data-testid="tonfall-dropdown-duplicate"
                                                            >
                                                                <FontAwesomeIcon icon={faCopy} className="mr-2 text-gray-500" />
                                                                Duplizieren
                                                            </DropdownMenu.Item>
                                                            <DropdownMenu.Item
                                                                className="flex items-center px-4 py-2 cursor-pointer rounded-[8px] hover:bg-gray-100 transition-colors duration-200 focus:outline-none"
                                                                onClick={() => handleSetDefault(item.id)}
                                                                data-testid="tonfall-dropdown-standard"
                                                            >
                                                                <FontAwesomeIcon icon={faStar} className="mr-2 text-gray-500" />
                                                                Als Standard auswählen
                                                            </DropdownMenu.Item>
                                                        </DropdownMenu.Content>
                                                    </DropdownMenu.Portal>
                                                </DropdownMenu.Root>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p className="text-center text-gray-600">Du hast noch keine Tonfälle mit dem Namen oder Inhalt "{searchTerm}" erstellt.</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default WelcomeView;
