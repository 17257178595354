import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import '../../types/globals.d'
import firebase, { auth, functions } from "../../config/fbConfig";
import { usePostHog } from 'posthog-js/react';
import 'swiper/css';
import { httpsCallable } from "firebase/functions";
import DynamicTemplates from '~/components/sections/DynamicTemplates'
import { OnboardingSteps, OnboardingStepsInfo } from '~/components/sections/OnboardingSteps'
import RecentDocuments from '~/components/sections/RecentDocuments'
import LayoutInSide from '~/layouts/LayoutInSide'
// Modular Fireabse SDK imports
import { useModal } from "../../providers/ModalProvider";
import { ModalTypeEnum } from "../../consts";
import { useShareableData } from "../../providers/ShareableDataProvider";
import SpinningLoader from "../../components/loader/spinning_loader";
import SkeletonTemplatePage from '~/components/sections/SkeletonTemplatePage'

const TemplatesPage = () => {
    // Providers
    const location = useLocation();
    const posthog = usePostHog();
    const { setActiveModal } = useModal();
    const { showOnBoarding } = useShareableData()

    // State Variables
    const isFromVerifyPage = location.pathname === "/welcome";
    const isFromSuccessPage = location.pathname === "/success";
    const [showWelcomePopup] = useState(isFromVerifyPage);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { onboardingData } = useShareableData();
    const isOnboardingActive = OnboardingStepsInfo.some(card => onboardingData && onboardingData[card.action]?.done === false); // check if steps for homepage onboarding sections is not completed
    const [isLoading, setIsLoading] = useState(true);
    const [showFullLoader, setShowFullLoader] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
  
    // Functions & Event Handlers
    /**
     * Loads the price for a given price ID from the Firebase database.
     * @param priceId The ID of the price to load.
     * @returns The price value, or null if the price is not found.
     */
    const loadPriceForID = async (priceId: string): Promise<number | null> => {
        try {
            // Query the Firestore database for active products
            const snapshot = await firebase
                .firestore()
                .collection("products")
                .where("active", "==", true)
                .get();

            // Loop through the product documents and query the prices subcollection for the given price ID
            for (const productDoc of snapshot.docs) {
                const priceSnapShot = await productDoc.ref.collection("prices").doc(priceId).get();

                // If the price document exists, return the price as a number
                if (priceSnapShot.exists) {
                    const price = priceSnapShot.data()?.unit_amount;
                    return price ? price / 100 : null;
                }
            }

            // If the price could not be found, return null
            return null;
        } catch (error) {
            // If an error occurs, log the error and return null
            console.error(error);
            return null;
        }
    };

    /**
  * This function handles subscription tracking by sending conversion events
  * to Google Tag Manager, Facebook Pixel, and other tracking systems.
  * It checks the `sessionStorage` to prevent the tag from firing multiple times.
  */
    const handleSubscriptionTracking = async () => {
        // Check the storage item to prevent the tag from firing multiple times
        const hasRun = sessionStorage.getItem("subscriptionTracking");

        // If the tag has not run and the user is coming from the success page, send the conversion event
        if (!hasRun && isFromSuccessPage) {


            // If the page is not fully loaded, wait for it to load
            if (document.readyState !== 'complete') {
                window.addEventListener('load', () => {
                    handleSubscriptionTracking();
                });
                return;
            }

            // Load the price
            const params = new URLSearchParams(location.search);
            const priceId = params.get("priceId");

            // If the price ID is present in the URL parameters, load the price and send the conversion event
            const price = priceId ? await loadPriceForID(priceId) : 29;
            try {
                // Needs to fire before the actual event code, otherwise the attribute is not set
                // ref: https://support.google.com/google-ads/answer/13262500
                (window as any).dataLayer.push({
                    'email_address': auth.currentUser?.email,
                });

                // Google Enhanced Conversion Tracking Code
                // Needs to fire before the actual event code, otherwise the attribute is not set
                // ref: https://support.google.com/google-ads/answer/13262500
                if (typeof window !== 'undefined' && (window as any).dataLayer) {
                    (window as any).dataLayer.push({
                        'email_address': auth.currentUser?.email,
                    });
                }

                // Google Tag Manager code for conversion tracking
                (window as any).dataLayer.push({
                    'event': 'SubscriptionCreated',
                    'value': price,
                    'currency': 'EUR'
                });

                // Bing UET tag for conversion tracking
                (window as any).uetq.push(
                    'event', 'SubscriptionCreated', {
                    "revenue_value": price,
                    "currency": "EUR"
                });

                // Facebook Pixel Purchase event tracking
                if (typeof window !== 'undefined' && (window as any).fbq) {
                    (window as any).fbq('track', 'Purchase', {
                        'value': price,
                        'currency': 'EUR',
                    });
                }

                // Set the storage item to prevent the tag from firing multiple times
                sessionStorage.setItem("subscriptionTracking", "true");

            } catch (error) {
                console.error(error);
            }

            // Posthog event success_page:pageview
            posthog.capture('success_page:pageview', {
                price_id: priceId,
                price: price
            });
        }
    };

    const handleSignUpTracking = () => {	
        // Check if the tracking has already been done	
        const hasRun = sessionStorage.getItem("leadTracking");	

        // If the page is not fully loaded, wait for it to load	
        if (document.readyState !== 'complete') {	
            window.addEventListener('load', () => {	
                handleSignUpTracking();	
            });	
            return;	
        }

        if (!hasRun) { // Test if cookie lowers our true conversion.	

            // Facebook Pixel Lead event tracking	
            if (typeof window !== 'undefined' && (window as any).fbq) {	
                (window as any).fbq('track', 'Lead');	
            }	

            // Google Enhanced Conversion Tracking Code	
            // Needs to fire before the actual event code, otherwise the attribute is not set	
            // ref: https://support.google.com/google-ads/answer/13262500	
            if (typeof window !== 'undefined' && (window as any).dataLayer) {	
                (window as any).dataLayer.push({	
                    'email_address': auth.currentUser?.email,	
                });	
            }	

            // Google Tag Manager code for conversion tracking	
            if (typeof window !== 'undefined' && (window as any).dataLayer) {	
                (window as any).dataLayer.push({	
                    'event': 'SignUp',	
                });	
            }	

            // Bing UET tag for conversion tracking	
            if (typeof window !== 'undefined' && (window as any).uetq) {	
                (window as any).uetq.push(	
                    'event', 'SignUp', {	
                });	
            }	

            // Posthog event sign_up:completed	
            let allCookiesAccepted = false;                    	
            // Add this code to print the cookie	
            const iubCookie = document.cookie	
                .split('; ')	
                .find(row => row.startsWith('_iub_cs-91682523='));	
            if (iubCookie) {	
                // Extract the value part after the '='	
                const cookieValue = iubCookie.split('=')[1];	
                // Decode the URL-encoded string and parse the JSON	
                const decodedCookie = JSON.parse(decodeURIComponent(cookieValue));	

                // Check if purpose exists and all its keys are true	
                if (decodedCookie.purposes) {	
                    const allPurposesAccepted = Object.values(decodedCookie.purposes).every(value => value === true);	
                    if (allPurposesAccepted) {	
                        allCookiesAccepted = true	
                    }	
                }	
            }	

            posthog.capture('sign_up:completed', {	
                cookies_accepted: allCookiesAccepted	
            });	
            // Set a cookie to prevent tracking from firing multiple times	
            sessionStorage.setItem("leadTracking", "true");	
        }	
    };	

    useEffect(() => {	
        if (isFromVerifyPage) {	
            handleSignUpTracking();	
        }	

        // Disable the eslint warning here, since we only want to run this once	
        // eslint-disable-next-line react-hooks/exhaustive-deps	
    }, [isFromVerifyPage]);	
    /* UseEffect Hooks */

    // Add event listener to window resize to update state
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        // Return cleanup function to remove event listener when unmounting
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

    // Track welcome_page loads
    useEffect(() => {
        if (showWelcomePopup && showOnBoarding) {
            posthog.capture('welcome_page:load');
            setActiveModal(ModalTypeEnum.OnboardingModal)
        }
        // Disable the eslint warning here, since we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnBoarding]);

    useEffect(() => {
        if (isFromVerifyPage) {
            const user_invited_success = httpsCallable(functions, "user_invited_success");
            user_invited_success()
        }
        // Disable the eslint warning here, since we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        handleSubscriptionTracking();
        // Disable the eslint warning here, since we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFromSuccessPage]);
    

    return (
        <LayoutInSide>
            {isLoading && <SpinningLoader />}
            {showFullLoader && <SpinningLoader />}
            {showSkeleton && <SkeletonTemplatePage />}
            <div className={(isLoading || showFullLoader || showSkeleton) ? 'hidden' : ''}>
                <DynamicTemplates
                    setShowFullLoader={setShowFullLoader}
                    setShowSkeleton={setShowSkeleton}
                />
                <RecentDocuments onLoadComplete={() => setIsLoading(false)}/>
                {isOnboardingActive && <OnboardingSteps/>}
            </div>
        </LayoutInSide>
    );
};

export default TemplatesPage;