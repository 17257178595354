import { useEffect } from "react";

const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (!textAreaRef) return;

    // Save the current height
    const currentHeight = textAreaRef.style.height;

    const adjustHeight = () => {
      // Set a minimum height instead of 0
      textAreaRef.style.height = "auto";
      textAreaRef.style.height = `${Math.max(textAreaRef.scrollHeight, 24)}px`;
    };

    // Add resize observer to handle dynamic content changes
    const resizeObserver = new ResizeObserver(adjustHeight);
    resizeObserver.observe(textAreaRef);

    // Initial adjustment
    adjustHeight();

    // Cleanup function
    return () => {
      resizeObserver.disconnect();
      textAreaRef.style.height = currentHeight;
    };
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;