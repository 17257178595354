import { useState, useEffect } from "react"
import type { Editor as TinyMCEEditor } from "tinymce"

interface SelectionPosition {
  selectionRect: DOMRect | null;
  editorRect: DOMRect | null;
}

/**
 * Tracks the bounding rect (in page coordinates) of the TinyMCE caret/selection.
 * Provides a fallback to the paragraph if the caret bounding rect is empty.
 * Adjusts coordinates relative to the editor's position.
 */
export function useSelectionPosition(editor: TinyMCEEditor | null): SelectionPosition {
  const [selectionRect, setSelectionRect] = useState<DOMRect | null>(null)
  const [editorRect, setEditorRect] = useState<DOMRect | null>(null)

  useEffect(() => {
    if (!editor) return

    const handleSelectionChange = () => {
      if (!editor.selection) {
        setSelectionRect(null)
        setEditorRect(null)
        return
      }

      const range = editor.selection.getRng()
      if (!range) {
        setSelectionRect(null)
        setEditorRect(null)
        return
      }

      // Get editor iframe position
      const editorIframe = editor.getContentAreaContainer()?.querySelector('iframe')
      if (!editorIframe) {
        setSelectionRect(null)
        setEditorRect(null)
        return
      }
      const iframeRect = editorIframe.getBoundingClientRect()
      setEditorRect(iframeRect)

      let rect: DOMRect | null = null

      // If we have a range and it's not collapsed, use getBoundingClientRect()
      if (!range.collapsed) {
        rect = range.getBoundingClientRect()
      } else {
        // Caret is collapsed: try getClientRects(). If empty, fallback to the parent node
        const rects = range.getClientRects()
        if (rects.length > 0) {
          rect = rects[rects.length - 1]
        } else {
          const node = editor.selection.getNode()
          if (node instanceof Element) {
            rect = node.getBoundingClientRect()
          }
        }
      }

      // Adjust the rect coordinates relative to the editor iframe
      if (rect) {
        const adjustedRect = new DOMRect(
          rect.x + iframeRect.x,
          rect.y + iframeRect.y,
          rect.width,
          rect.height
        )
        setSelectionRect(adjustedRect)
      } else {
        setSelectionRect(null)
      }
    }

    editor.on("SelectionChange", handleSelectionChange)
    handleSelectionChange() // Initial run

    return () => {
      editor.off("SelectionChange", handleSelectionChange)
    }
  }, [editor])

  return { selectionRect, editorRect }
} 