import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import TemplateCard from "../../components/cards/TemplateCard";
import { useLocation } from "react-router-dom";
import LayoutInSide from "../../layouts/LayoutInSide";
import { useNavigate } from "react-router-dom";
import '../../types/globals.d'
import firebase, { doc, onSnapshot, getDoc, firestore, auth } from "../../config/fbConfig";
import SpinningLoader from "../../components/loader/spinning_loader";
import { getUserTemplates } from "../../utils/userUtils";
import { usePostHog } from 'posthog-js/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from "swiper";
import 'swiper/css';
import GenericModal from '../../layouts/Modal/GenericModal';
import { Reorder, motion } from 'framer-motion'
import { DragandDropIcon, CollapseArrowDOWN, CollapseArrowUP } from "../../assets/Icons";

// Modular Fireabse SDK imports
import { collection, setDoc, updateDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useManageStripeSubscription } from "../../utils/ManageStripeSubscription";
import { Button } from "../../components/ui/button";

import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { TemplateManager } from "../../utils/templates/TemplateManager";
import { useUserData } from '../../providers/UserDataProvider';

interface ITag {
    type: string;
    collapsed: boolean;
}

interface Template {
    id: string;
    title: string;
    type: string;
    description: string;
    iconName: string;
    iconColor: string;
    favorite?: boolean;
    // Add any other properties that your templates might have
}

const tagsconst = [
    { type: "alle", collapsed: false },
    { type: "Persönliche Vorlagen", collapsed: false },
    { type: "Allgemein", collapsed: false },
    { type: "Social media", collapsed: false },
    { type: "Email", collapsed: false },
    { type: "Blog", collapsed: false },
    { type: "Ads", collapsed: false },
];

const TemplatesPage = () => {
    // Providers
    const templateDetail = useLocation();
    const navigate = useNavigate();
    const posthog = usePostHog();
    const manageStripeSubscription = useManageStripeSubscription();
    const { templates: providedTemplates, isLoading: userDataLoading } = useUserData();

    // State Variables
    const [query, setQuery] = useState("");
    const [tags, setTags] = useState<ITag[]>(tagsconst);
    const isFromDynamicTemplatePage = templateDetail.state?.showDynTemplatePopUp || false;
    const isFromDynamicEditPage = templateDetail.state?.showEditTemplatePopUp || false;
    const [allTemplates, setAllTemplates] = useState<Template[]>([]);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showDynamicTemplatePopup, setShowDynamicTemplatePopup] = useState(isFromDynamicTemplatePage || false);
    const [showDynamicEditPopup, setShowDynamicEditPopup] = useState(isFromDynamicEditPage || false);
    const [draggable, setDraggable] = useState(false);
    const [isFreeTrial, setIsFreeTrial] = useState(false);
    const { userData } = useUserData();
    useEffect(() => {
        if (userData?.userData?.freetrial !== undefined) {
            setIsFreeTrial(userData.userData.freetrial);
        }
    }, [userData]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);

    // Functions & Event Handlers

    const processTemplates = async (docSnap: any) => {
        if (!auth.currentUser) {
            navigate("/sign-in");
            return;
        }

        let templates_out: Template[] = [...providedTemplates] as Template[];
        
        if (docSnap.exists()) {
            const data = docSnap.data();
            let templatesOrder = data.pinned_templates || [];

            let updatedTemplates = templates_out.map(template_idx => ({
                ...template_idx,
                favorite: templatesOrder.includes(template_idx.id)
            }));
            
            templates_out = updatedTemplates;

            templates_out.sort((a, b) => {
                let aIsInTemplates = templatesOrder.includes(a.id);
                let bIsInTemplates = templatesOrder.includes(b.id);

                if (aIsInTemplates && bIsInTemplates) {
                    return 0;
                } else if (aIsInTemplates) {
                    return -1;
                } else if (bIsInTemplates) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        setAllTemplates(templates_out);
    };

    useEffect(() => {
        if (!auth.currentUser) return;

        const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser.uid, "writedata"), 'appdata');
        
        // Initial load
        getDoc(writeDataDocRef).then(processTemplates);
        
        // Subscribe to changes
        const unsubscribe = onSnapshot(writeDataDocRef, processTemplates);

        return () => unsubscribe();
    }, [providedTemplates]);

    const toggleCollapse = async (type: string) => {
        const updatedTags = tags.map((tag) =>
            tag.type === type ? { ...tag, collapsed: !tag.collapsed } : tag
        );
        setTags(updatedTags);
        await updateDatabase(updatedTags);
    };

    const handleReorder = (newTags: ITag[]) => {
        setTags(newTags);
        handleOrderChange(newTags);
    };

    const getTagsOrder = async () => {
        const docRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const currentState = docSnapshot.data();
            const newTags = currentState.templatesOrder;
            if (newTags) {
                setTags(newTags);
            }
        }
    };

    const handleOrderChange = async (tags: ITag[]) => {
        await updateDatabase(tags);
    };

    const updateDatabase = async (tags: ITag[]) => {
        const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
        const docSnapshot = await getDoc(writeDataDocRef);
        if (docSnapshot.exists()) {
            await updateDoc(writeDataDocRef, { templatesOrder: tags });
        } else {
            await setDoc(writeDataDocRef, { templatesOrder: tags });
        }

    };

    /* UseEffect Hooks */

    // Add event listener to window resize to update state
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        // Return cleanup function to remove event listener when unmounting
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount


    useEffect(() => {
        getTagsOrder();
    }, [])

    const handleDeleteTemplate = async () => {
        if (templateToDelete && auth.currentUser) {
            const templateManager = new TemplateManager(auth.currentUser.uid, templateToDelete);
            await templateManager.delete();
            setIsDeleteDialogOpen(false);
            setTemplateToDelete(null);
        }
    };

    if (userDataLoading) {
        return <SpinningLoader />
    } else {
        return (
            <>
                <LayoutInSide
                    onSearch={(e) => {
                        const newQuery = e.target.value.toString().toLowerCase();
                        setQuery(newQuery);

                        // This works by setting a timeout to run after the user stops typing
                        // If the user types again before the timeout runs, the timeout is cleared
                        // and a new one is set
                        // Basically this prevents the logging from running on every keystroke and
                        // only runs after the user stops typing for 1 second

                        // Clear the previous timeout if there is one
                        if (timeoutRef.current) {
                            clearTimeout(timeoutRef.current);
                        }

                        // Set a new timeout
                        timeoutRef.current = setTimeout(() => {
                            // Add posthog tracking for template search
                            if (newQuery.length > 0) {
                                posthog.capture('templates_page:template_search', {
                                    search_query: newQuery
                                });
                            }
                        }, 1000); // 1 second delay
                    }}
                >
                    <div className="w-full">
                        <div className="mb-4 md:mb-8 flex lg:flex-row flex-col md:gap-16 gap-6 items-start justify-between w-full">
                            <div className="flex items-center gap-8 sm:gap-8 justify-start w-full">
                                <h1 className="font-Outfit text-[32px] font-semibold hidden md:block">Vorlagen</h1>
                            </div>

                        </div> {(query !== "") && (query !== "Other") ? (
                            <div key={`template-tag-search}`}>
                                <div className="w-full flex justify-start items-center mb-4">
                                    <h3 className="pr-6 text-xl font-semibold font-Outfit whitespace-nowrap">Suche</h3>
                                    <div className="bg-#EDEDED w-full border-b flex items-center justify-center"></div>
                                </div>

                                <div className="flex justify-center items-center">
                                    <Swiper
                                        style={{
                                            maxWidth: `${windowWidth > 768 ? windowWidth - 300 : "100%"
                                                }px`
                                        }}

                                        className="h-auto w-full pb-8 pl-1"
                                        modules={[Navigation, Mousewheel]}
                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                        }}
                                        freeMode={true}
                                        spaceBetween={10}
                                        mousewheel={false}
                                        slidesPerGroup={4}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 2.1,
                                                slidesPerGroup: 2
                                            },
                                            768: {
                                                slidesPerView: 2.1,
                                                slidesPerGroup: 2
                                            },
                                            1024: {
                                                slidesPerView: 3.2,
                                                slidesPerGroup: 3
                                            },
                                            1440: {
                                                slidesPerView: 4.2,
                                                slidesPerGroup: 4
                                            }
                                        }}
                                    >
                                        {
                                            allTemplates
                                                .filter((curtemplate) => curtemplate.title.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "").includes(query.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")))
                                                .length > 0 ?
                                                allTemplates
                                                    .filter((curtemplate) => curtemplate.title.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "").includes(query.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")))
                                                    .map((curtemplate, index) => (
                                                        <SwiperSlide className="h-auto" key={`template-2${index}`}>
                                                            <div className="h-full">
                                                                <TemplateCard items={curtemplate} />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))
                                                :
                                                <div className="w-full justify-center items-center text-center">
                                                    <p>
                                                        Es wurden leider keine Vorlagen zu der Suche "{query}" gefunden.
                                                    </p>
                                                </div>
                                        }
                                        <button
                                            className="z-10 left-0 swiper-button-prev after:hidden !bg-transparent">
                                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect width="22" height="21" rx="2" fill="#9C68EF" />
                                                <path
                                                    transform="scale(-1, 1) translate(-22, 0)"
                                                    d="M10.4414 16.899L16.8162 11.3675C16.8739 11.3175 16.9202 11.2556 16.9519 11.1862C16.9836 11.1168 17 11.0413 17 10.965C17 10.8886 16.9836 10.8132 16.9519 10.7437C16.9202 10.6743 16.8739 10.6125 16.8162 10.5625L10.4414 5.0326C10.4222 5.01589 10.3985 5.00505 10.3733 5.00139C10.3481 4.99772 10.3224 5.00137 10.2992 5.01191C10.2761 5.02244 10.2564 5.03942 10.2426 5.06082C10.2288 5.08222 10.2214 5.10713 10.2214 5.1326L10.2214 6.48257C10.2214 6.55923 10.2547 6.63256 10.313 6.68423L14.5179 10.3325L5.13316 10.3325C5.05983 10.3325 4.99983 10.3925 4.99983 10.4658L4.99983 11.4658C4.99983 11.5391 5.05983 11.5991 5.13316 11.5991L14.5179 11.5991L10.313 15.2474C10.2547 15.2974 10.2214 15.3707 10.2214 15.449L10.2214 16.799C10.2214 16.9123 10.3547 16.974 10.4414 16.899Z"
                                                    fill="white" />
                                            </svg>
                                        </button>
                                        <button
                                            className="z-10 right-0 swiper-button-next after:hidden !bg-transparent">
                                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect width="22" height="21" rx="2" fill="#9C68EF" />
                                                <path
                                                    d="M10.4414 16.899L16.8162 11.3675C16.8739 11.3175 16.9202 11.2556 16.9519 11.1862C16.9836 11.1168 17 11.0413 17 10.965C17 10.8886 16.9836 10.8132 16.9519 10.7437C16.9202 10.6743 16.8739 10.6125 16.8162 10.5625L10.4414 5.0326C10.4222 5.01589 10.3985 5.00505 10.3733 5.00139C10.3481 4.99772 10.3224 5.00137 10.2992 5.01191C10.2761 5.02244 10.2564 5.03942 10.2426 5.06082C10.2288 5.08222 10.2214 5.10713 10.2214 5.1326L10.2214 6.48257C10.2214 6.55923 10.2547 6.63256 10.313 6.68423L14.5179 10.3325L5.13316 10.3325C5.05983 10.3325 4.99983 10.3925 4.99983 10.4658L4.99983 11.4658C4.99983 11.5391 5.05983 11.5991 5.13316 11.5991L14.5179 11.5991L10.313 15.2474C10.2547 15.2974 10.2214 15.3707 10.2214 15.449L10.2214 16.799C10.2214 16.9123 10.3547 16.974 10.4414 16.899Z"
                                                    fill="white" />
                                            </svg>
                                        </button>
                                    </Swiper>
                                </div>
                            </div>
                        ) : (
                            <Reorder.Group values={tags} onReorder={handleReorder}>
                                {tags.map((tag, index) => {
                                    const filteredTemplates = allTemplates.filter(
                                        (curtemplate) => curtemplate.type.toLowerCase() === tag.type.toLowerCase()
                                    );

                                    if (filteredTemplates.length === 0) return null; // Do not display the tag if it doesn't contain filtered items
                                    return (
                                        <Reorder.Item
                                            value={tag}
                                            key={tag.type}
                                            dragListener={draggable}
                                            onDragEnd={() => setDraggable(false)}
                                        >
                                            <motion.div key={`template-tag-${index}`} layout layoutRoot>
                                                <motion.div className="w-full items-center mb-4" layout>
                                                    <div className="flex w-full relative items-center justify-between">
                                                        <motion.div
                                                            className="md:mr-4 cursor-pointer"
                                                            onMouseEnter={() => setDraggable(true)}
                                                            onMouseLeave={() => setDraggable(false)}
                                                            onTouchStart={() => setDraggable(true)}
                                                        >
                                                            <DragandDropIcon />
                                                        </motion.div>
                                                        <button
                                                            onClick={() => toggleCollapse(tag.type)}
                                                            className="flex items-center bg-#EDEDED rounded-md ml-auto"
                                                        >
                                                            {tag.collapsed ? (
                                                                <CollapseArrowUP />
                                                            ) : (
                                                                <CollapseArrowDOWN />
                                                            )}
                                                        </button>
                                                        <h3 className="pr-2 text-xl font-semibold font-Outfit whitespace-nowrap">{tag.type}</h3>
                                                        <div className="bg-#EDEDED w-full items-center border-b flex justify-center"></div>
                                                        <Link
                                                            to={`/templates/list/${tag.type}`}
                                                            className={`ml-2 items-center flex text-[#AAA8BD] text-xs ${
                                                                (tag.type === "Persönliche Vorlagen" && isFreeTrial && 
                                                                    filteredTemplates.length > 3 ) 
                                                                    ? 'pointer-events-none opacity-50' 
                                                                    : ''
                                                            }`}
                                                        >
                                                            <span className="md:w-24">Alle anzeigen</span>
                                                            <FontAwesomeIcon 
                                                                icon={faArrowRight}
                                                                className="pl-2 group-hover:text-white transition-colors duration-200" 
                                                            />
                                                        </Link>
                                                    </div>
                                                </motion.div>
                                                {!tag.collapsed && (
                                                    <div className="flex justify-center items-center">
                                                        <Swiper
                                                            style={{
                                                                maxWidth: `${windowWidth > 768 ? windowWidth - 300 : "100%"}px`
                                                            }}
                                                            className="h-auto w-full pb-8 pl-1"
                                                            modules={[Navigation, Mousewheel]}
                                                            navigation={{
                                                                nextEl: '.swiper-button-next',
                                                                prevEl: '.swiper-button-prev',
                                                            }}
                                                            freeMode={true}
                                                            spaceBetween={10}
                                                            mousewheel={false}
                                                            slidesPerGroup={4}
                                                            breakpoints={{
                                                                320: {
                                                                    slidesPerView: 2.1,
                                                                    slidesPerGroup: 2,
                                                                },
                                                                768: {
                                                                    slidesPerView: 2.1,
                                                                    slidesPerGroup: 2,
                                                                },
                                                                1024: {
                                                                    slidesPerView: 3.2,
                                                                    slidesPerGroup: 3,
                                                                },
                                                                1440: {
                                                                    slidesPerView: 4.2,
                                                                    slidesPerGroup: 4,
                                                                }
                                                            }}
                                                        >
                                                            {filteredTemplates.map((curtemplate, index) => {
                                                                const isPersonalTemplatesLimited = tag.type === "Persönliche Vorlagen" && 
                                                                                             isFreeTrial && 
                                                                                             index >= 4;
                                                                                            
                                                                const isBlurred = !userDataLoading &&
                                                                                 tag.type === "Persönliche Vorlagen" && 
                                                                                 isFreeTrial && 
                                                                                 index === 3 && 
                                                                                 filteredTemplates.length > 3;

                                                                if (isPersonalTemplatesLimited) return null;

                                                                return (
                                                                    <SwiperSlide 
                                                                        className={`h-auto relative ${isBlurred ? 'pointer-events-none' : ''}`} 
                                                                        key={`template-2${index}`}
                                                                    >
                                                                        <div className={`h-full ${isBlurred ? 'blur-sm' : ''}`}>
                                                                            <TemplateCard items={{
                                                                            ...curtemplate,
                                                                            onDelete: () => {
                                                                                setTemplateToDelete(curtemplate.id);
                                                                                setIsDeleteDialogOpen(true);
                                                                            }
                                                                        }} />
                                                                        </div>
                                                                        {isBlurred && (
                                                                            <div className="absolute inset-0 flex flex-col items-center justify-center bg-white/80 rounded-lg p-4 pointer-events-auto">
                                                                                <img
                                                                                    src="/assets/images/padlock.png"
                                                                                    alt="Locked"
                                                                                    className="hidden md:block md:w-12 md:h-12 mb-2"
                                                                                />
                                                                                <h3 className="text-center font-semibold mb-2 text-xs md:text-base">
                                                                                    Upgrade für mehr Vorlagen
                                                                                </h3>
                                                                                <p className="text-[10px] md:text-[11px] text-center text-gray-600 mb-4 font-poppins">
                                                                                    Erstelle unbegrenzt persönliche Vorlagen mit unserer Premium-Version
                                                                                </p>
                                                                                <Button
                                                                                    onClick={() => manageStripeSubscription()}
                                                                                    className="bg-secondary hover:bg-secondary-600 text-white text-xs md:text-sm"
                                                                                >
                                                                                    Upgrade
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </SwiperSlide>
                                                                );
                                                            })}
                                                            <button className="z-10 left-0 swiper-button-prev after:hidden !bg-transparent">
                                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="22" height="21" rx="2" fill="#9C68EF" />
                                                                    <path
                                                                        transform="scale(-1, 1) translate(-22, 0)"
                                                                        d="M10.4414 16.899L16.8162 11.3675C16.8739 11.3175 16.9202 11.2556 16.9519 11.1862C16.9836 11.1168 17 11.0413 17 10.965C17 10.8886 16.9836 10.8132 16.9519 10.7437C16.9202 10.6743 16.8739 10.6125 16.8162 10.5625L10.4414 5.0326C10.4222 5.01589 10.3985 5.00505 10.3733 5.00139C10.3481 4.99772 10.3224 5.00137 10.2992 5.01191C10.2761 5.02244 10.2564 5.03942 10.2426 5.06082C10.2288 5.08222 10.2214 5.10713 10.2214 5.1326L10.2214 6.48257C10.2214 6.55923 10.2547 6.63256 10.313 6.68423L14.5179 10.3325L5.13316 10.3325C5.05983 10.3325 4.99983 10.3925 4.99983 10.4658L4.99983 11.4658C4.99983 11.5391 5.05983 11.5991 5.13316 11.5991L14.5179 11.5991L10.313 15.2474C10.2547 15.2974 10.2214 15.3707 10.2214 15.449L10.2214 16.799C10.2214 16.9123 10.3547 16.974 10.4414 16.899Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <button className="z-10 right-0 swiper-button-next after:hidden !bg-transparent">
                                                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="22" height="21" rx="2" fill="#9C68EF" />
                                                                    <path
                                                                        d="M10.4414 16.899L16.8162 11.3675C16.8739 11.3175 16.9202 11.2556 16.9519 11.1862C16.9836 11.1168 17 11.0413 17 10.965C17 10.8886 16.9836 10.8132 16.9519 10.7437C16.9202 10.6743 16.8739 10.6125 16.8162 10.5625L10.4414 5.0326C10.4222 5.01589 10.3985 5.00505 10.3733 5.00139C10.3481 4.99772 10.3224 5.00137 10.2992 5.01191C10.2761 5.02244 10.2564 5.03942 10.2426 5.06082C10.2288 5.08222 10.2214 5.10713 10.2214 5.1326L10.2214 6.48257C10.2214 6.55923 10.2547 6.63256 10.313 6.68423L14.5179 10.3325L5.13316 10.3325C5.05983 10.3325 4.99983 10.3925 4.99983 10.4658L4.99983 11.4658C4.99983 11.5391 5.05983 11.5991 5.13316 11.5991L14.5179 11.5991L10.313 15.2474C10.2547 15.2974 10.2214 15.3707 10.2214 15.449L10.2214 16.799C10.2214 16.9123 10.3547 16.974 10.4414 16.899Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </Swiper>
                                                    </div>
                                                )}
                                            </motion.div>
                                        </Reorder.Item>
                                    );
                                })}
                            </Reorder.Group>
                        )}
                    </div>
                </LayoutInSide>

                <GenericModal
                    isOpen={showDynamicTemplatePopup || showDynamicEditPopup}
                    title={`Deine ${showDynamicTemplatePopup ? 'Persöhnliche Vorlage' : 'Änderung'} ist in Arbeit!`}
                    content={[
                        `Die ${showDynamicTemplatePopup ? 'Erstellung' : 'Änderung'} kann bis zu zwei Minuten dauern. Wenn der Vorgang abgeschlossen ist, erscheint deine Vorlage neben allen anderen Vorlagen auf der folgenden Seite.`
                    ]}
                    buttonText="Perfekt, vielen Dank 😊"
                    image="/assets/images/partying_face.png"
                    imagePlacement="top"
                    onClose={() => {
                        navigate("/home");
                    }}
                    onButtonClick={() => {
                    }}
                    closeModal={() => {
                        setShowDynamicTemplatePopup(false);
                        setShowDynamicEditPopup(false);
                    }}
                />

                {isDeleteDialogOpen && (
                    <AlertDialog open={isDeleteDialogOpen} onOpenChange={(open) => {
                        setIsDeleteDialogOpen(open)
                        setTimeout(() => {
                            if (!open) {
                                document.body.style.pointerEvents = ''
                            }
                        }, 100)
                    }}>
                        <AlertDialogContent className="bg-white">
                            <AlertDialogHeader>
                                <AlertDialogTitle>Bist Du sicher?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    Diese Aktion kann nicht rückgängig gemacht werden. Dies wird deine Vorlage dauerhaft löschen.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel onClick={() => setIsDeleteDialogOpen(false)}>Abbrechen</AlertDialogCancel>
                                <AlertDialogAction
                                    onClick={handleDeleteTemplate}
                                    className="bg-red-600 hover:bg-red-700"
                                >
                                    Löschen
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                )}
            </>
        );
    }
    ;
};

export default TemplatesPage;