import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Container from "../../components/Container";
import NavbarOutSide from "../../layouts/NavbarAuth";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import Footer from "../../layouts/FooterInside";
import { toast } from "react-toastify";
import { ClosedEye, Eye } from "../../assets/Icons";
import { auth } from "../../config/fbConfig";
import { checkUserState, handleUserRedirect } from "../../utils/loginUtils";
import GoogleSignInButton from "../../components/Google-sign-in";
import { Helmet } from "react-helmet";
import { useDistinctIdFromUrl, useHandleUserIdentification, appendDistinctIdToUrl } from '../../utils/UserTrackingUtils';
import { usePostHog } from 'posthog-js/react';
import SpinningLoader from "../../components/loader/spinning_loader";

const SignInPage = () => {

  // State Variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage] = useState("");
  const [isShowPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // Providers
  const navigate = useNavigate();
  const location = useLocation();
  const provider = new GoogleAuthProvider();
  const posthog = usePostHog();

  // Get distinct id from url, relevant for cross-site-tracking
  const distinctId = useDistinctIdFromUrl();
  // Get distinct id from url and identify user
  useHandleUserIdentification(distinctId);

  // Get discount code from URL
  const searchParams = new URLSearchParams(location.search);
  const discountCode = searchParams.get('discount') || '';

  // Functions and Event Handlers

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((res: any) => {

        if (posthog) {
          try {
            posthog.capture('sign_in:email_and_password', {
              $set: {
                last_sign_in: new Date(Number(res.user.metadata.lastLoginAt)).toISOString(),
              }
            });
          } catch (error) {
            console.error("Posthog error:", error);
          }
        }

        // Alias user with posthog to link anonymous login session to logged in session
        posthog.alias(res.user.uid, posthog.get_distinct_id());

        // Use the handleUserRedirect function to redirect the user to the correct page
        // Include the distinct id in the url, to enable cross-site-tracking
        // Pass the discount code to handleUserRedirect
        handleUserRedirect(auth as any, navigate, posthog, false, discountCode);
      })
      .catch((err) => {
        toast.error("Unbekannte E-mail oder unbekanntes Passwort.", {
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  /* UseEffect Hooks */

  // We dont need to modify the navigate here, since this only navigates if the user is logged in
  // in which case they should already be alias-ed or identified
  // should only run once, thus ignore the warning
  useEffect(() => {
    checkUserState(auth, navigate, posthog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading === true) return <SpinningLoader />;

  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Robowriter KI - Einloggen für Ihren persönlichen KI-Texter</title>
        <meta name="description" content="Robowriter ChatGPT - Ein KI-Schreibassistent für jede Anwendung" />
      </Helmet>
      <main className="min-h-screen h-full flex flex-col">
        <NavbarOutSide />
        <section className="flex-1 flex flex-col m-4 sm:justify-top lg:justify-center">
          <Container>
            <div className="flex flex-wrap lg:justify-start justify-center gap-9 items-start relative w-full">

              <div className="lg:w-2/3 order-1 lg:order-1 max-w-[551px] lg:pb-48 mx-auto relative">
                <div className="text-center w-full lg:max-w-[320px] mx-auto xl:max-w-none">
                  <h1 className="text-primary font-semibold lg:text-[32px] mb-2 text-[24px] leading-[40px] lg:max-w-none mx-0">
                    Anmelden
                  </h1>
                  <h2 className="mx-0 ml-2 text-gray-400 font-poppins">
                    Melde dich mit deinem bestehenden Konto an.
                  </h2>

                </div>
                <GoogleSignInButton
                  button_text="Login mit Google"
                  auth={auth}
                  provider={provider}
                  navigate={navigate}
                  newsletter_accepted={false}
                  setLoading={setLoading}
                  reqData={null}
                  discount={discountCode}
                  distinctId={distinctId ?? ''}
                />
                <div className="flex items-center justify-center my-3">
                  <hr className="flex-1 border-t-2 border-gray-300" />
                  <p className="mx-4 text-gray-500 font-semibold">oder</p>
                  <hr className="flex-1 border-t-2 border-gray-300" />
                </div>
                <form onSubmit={handleSubmit}>
                  <ul className="flex flex-col">
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="mb-1 block text-base font-medium text-primary"
                      >
                        E-Mail Adresse
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                        placeholder="E-Mail Adresse"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="password"
                        className="mb-1 block text-base font-medium text-primary"
                      >
                        Passwort
                      </label>
                      <div className="relative">
                        <input
                          type={isShowPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          className="block w-full border-0 p-3 font-light text-primary-500 bg-desertStrom placeholder-gray-500 focus:ring-0 rounded-lg text-sm"
                          placeholder="Passwort"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />

                        <span
                          onClick={() => setShowPassword(!isShowPassword)}
                          className="absolute right-3 top-1/2 -translate-y-1/2"
                        >
                          {isShowPassword ? <ClosedEye /> : <Eye />}
                        </span>
                      </div>
                    </div>
                    {errorMessage && (
                      <p className="text-red-500 text-xs mt-2">
                        {errorMessage}
                      </p>
                    )}
                    <div className="w-full flex justify-left mb-8">
                      <Link
                        to="/forgot-password"
                        className="text-primary hover:text-secondary text-[14px] font-medium"
                      >
                        Passwort vergessen?
                      </Link>
                    </div>
                    <button
                      type="submit"
                      className="btn-default font-bold w-full rounded-lg font-Outfit bg-secondary text-lg h-12 text-white tracking-[0.16px] border-2 border-secondary transition-all duration-200 ease-in-out px-8 hover:bg-transparent hover:text-secondary"
                    >
                      Login
                    </button>
                  </ul>
                  <ul>
                    <div className="text-base text-primary mt-4 text-center">
                      Haben Sie noch kein Konto?{" "}
                      <Link to={appendDistinctIdToUrl('/sign-up', distinctId)} className="text-secondary">
                        Erstellen Sie hier ein Konto
                      </Link>
                    </div>
                  </ul>
                </form>
              </div>
            </div>
          </Container>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default SignInPage;
