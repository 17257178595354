import React, { useState, useRef, useEffect } from 'react';
import { StarsStartIcon } from '~/assets/Icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoaderIcon } from 'lucide-react';
import { useShareableData } from "../../providers/ShareableDataProvider";
import { handleButtonClick } from '~/utils/templates/editTemplates';
import useAutosizeTextArea from "~/hooks/useAutosizeTextArea";
import { usePostHog } from 'posthog-js/react';
import { useUserData } from '../../providers/UserDataProvider';

interface DynamicTemplatesProps {
  setShowFullLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
}

const DynamicTemplates: React.FC<DynamicTemplatesProps> = ({ setShowFullLoader, setShowSkeleton }) => {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useUserData();
  const navigate = useNavigate();
  const { completeOnBoardingStep } = useShareableData();
  const [isTyping, setIsTyping] = useState(false);
  const typingSpeed = 15; // ms per character
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();

  useAutosizeTextArea(textAreaRef.current, inputText);

  useEffect(() => {
    const promptParam = searchParams.get('prompt');
    if (promptParam) {
      setInputText(promptParam);
      
      // Wait for next tick to ensure textarea is rendered
      setTimeout(() => {
        const textArea = textAreaRef.current;
        if (textArea) {
          // Focus the textarea
          textArea.focus();
          
          // Create and dispatch both keydown and keyup events
          const keydownEvent = new KeyboardEvent('keydown', {
            key: 'Enter',
            code: 'Enter',
            keyCode: 13,
            which: 13,
            bubbles: true,
            cancelable: true
          });
          
          const keyupEvent = new KeyboardEvent('keyup', {
            key: 'Enter',
            code: 'Enter',
            keyCode: 13,
            which: 13,
            bubbles: true,
            cancelable: true
          });

          textArea.dispatchEvent(keydownEvent);
          textArea.dispatchEvent(keyupEvent);
        }
      }, 0);
    }
  }, [searchParams]);

  const handleSubmit = () => {
    if (inputText.trim() && !isLoading) {
      handleButtonClick(inputText, setIsLoading, navigate, completeOnBoardingStep, posthog);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setShowFullLoader(true);
        setTimeout(() => {
          setShowFullLoader(false);
          setShowSkeleton(true);
          setTimeout(() => {
            setShowSkeleton(false);
          }, 60000);
        }, 1000);
      }, 500);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const typeText = (text: string) => {
    if (isTyping) return;
    setIsTyping(true);
    setInputText('');
    
    let currentText = '';
    let currentIndex = 0;

    const typeChar = () => {
      if (currentIndex < text.length) {
        currentText += text[currentIndex];
        setInputText(currentText);
        currentIndex++;
        setTimeout(typeChar, typingSpeed);
      } else {
        setIsTyping(false);
      }
    };

    typeChar();
  };


  return (
    <div className="flex flex-col items-center px-6 xl:px-20 pt-[15px] md:pt-6 lg:pt-8 xl:pt-9 pb-8 xl:pb-7 rounded-[10px] bg-[#f0e9fe]">
        <>
          <span className="text-black font-poppins text-[22px] font-medium leading-[33px] tracking-normal">
            Hallo {userData?.userData?.firstname ?? ""}!
          </span>
          <h2 className="mb-[22px] text-black font-poppins text-[22px] md:text-[24px] lg:text-[26px] font-semibold leading-[39px] tracking-normal">
            Was möchtest Du erstellen?
          </h2>
          <div className="flex items-center justify-between mb-6 py-[6px] pl-3 md:pl-4 pr-1.5 min-h-[54px] rounded-[5px] bg-white w-full">
            <textarea
              ref={textAreaRef}
              disabled={isLoading}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Beschreibe Deine Schreibaufgabe" 
              rows={1}
              data-testid="template-input"
              className="flex-1 resize-none overflow-hidden appearance-none border-none outline-none bg-transparent p-0 m-0 focus:ring-0 placeholder:text-[#aaa8bd] placeholder:font-poppins placeholder:text-xs md:placeholder:text-base placeholder:font-normal placeholder:tracking-[0.01em] placeholder:text-left cursor-text min-h-[24px]"
            />
            <button
              className="flex items-center justify-center w-[133px] h-[40px] gap-1 px-4 md:px-6 lg:px-8 rounded-[4px] bg-secondary hover:bg-secondary-600 text-white font-poppins text-[14px] font-medium leading-[21px] tracking-normal text-left"
              onClick={handleSubmit}
              disabled={isLoading || !inputText.trim()}
            >
              {isLoading ? <div className='flex items-center'>
                <LoaderIcon className="animate-spin h-4 text-white mr-[6px]" />
                Analysiere...
              </div> : (
                <>
                  <div className="hidden md:block w-[10px] mr-1 h-3"><StarsStartIcon /></div>
                  Starten
                </>
              )}
            </button>
          </div>
          <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            {buttonsDescriptions.map((solutionBtn, index) => (
              <button 
                key={index} 
                type="button" 
                className="group px-3 py-[18px] rounded-[5px] bg-white hover:bg-secondary transition-colors duration-100"
                onClick={() => typeText(solutionBtn.prompt)}
              >
                <h3 className="group-hover:text-white text-black mb-[2px] text-base font-poppins font-semibold tracking-normal text-left">
                  {solutionBtn.title}
                </h3>
                <span className="group-hover:text-[#e6e9f5] text-black/50 font-poppins text-xs font-medium tracking-normal text-left block">
                  {solutionBtn.description}
                </span>
              </button>
            ))}
          </div>
        </>
    </div>
  );
};

export default DynamicTemplates;

const buttonsDescriptions = [
  { 
    title: "E-Mail-Kampagne",
    description: "für eine Rabatt-Aktion",
    templateId: '49d94edd3b548b9a1872',
    prompt: "Erstelle überzeugende E-Mail für Rabatt-Aktion"
  },
  { 
    title: "Social-Media-Anzeige", 
    description: "für ein neues Produkt",
    templateId: 'H0P3o5WY1xK4HpQW3Zm8',
    prompt: "Entwickle Social-Media-Anzeige für neues Produkt"
  },
  { 
    title: "Produktbeschreibung",
    description: "für eine Produkteinführung",
    templateId: 'Y0F3o2MY3xA4XpQV7Xx8',
    prompt: "Verfasse überzeugende Beschreibung für neues Produkt"
  },
  { 
    title: "Blog-Beitrag",
    description: "über Erfolgsgeschichten von Kunden",
    templateId: 'I0P9o7WY3xN2HpQV8Yr4',
    prompt: "Schreibe Blog über Kundenerfolgsstories"
  }
];
