import { httpsCallable } from "firebase/functions";
import { doc, onSnapshot } from "firebase/firestore";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import { PostHog } from 'posthog-js';
import { DocumentEditorRef } from '../components/editor/DocumentEditor';
import { firestore, functions, gemini_model, modify_model } from "../config/fbConfig";
import { decrementCreations } from "../utils/userUtils";
import MarkdownIt from 'markdown-it';
import { Editor as TinyMCEEditor } from 'tinymce';

interface AIServiceConfig {
  editorRef: React.RefObject<DocumentEditorRef>;
  userId: string;
  posthog: PostHog;
}

interface GenerationParams {
  prompt: string;
  systemPrompt: string;
  template: string;
  shouldDecrement?: boolean;
}

export class AIService {
  private editorRef: React.RefObject<DocumentEditorRef>;
  private userId: string;
  private posthog: PostHog;
  private generation_id: string;
  private cancelRef: { current: boolean };
  private mdParser: MarkdownIt;
  private completeResponse: string = '';
  private previousContent: string | null = null;

  constructor(config: AIServiceConfig) {
    this.editorRef = config.editorRef;
    this.userId = config.userId;
    this.posthog = config.posthog;
    this.generation_id = '';
    this.cancelRef = { current: true };
    this.mdParser = new MarkdownIt({
      html: true,
      breaks: false,
      typographer: true,
    }).enable(['heading']);
  }

  /**
   * Cleans up any stored content state
   */
  public cleanupContent(): void {
    this.completeResponse = '';
    this.previousContent = null;
  }

  /**
   * Updates the editor reference if it changes
   */
  public updateEditorRef(newRef: React.RefObject<DocumentEditorRef>): void {
    this.editorRef = newRef;
  }

  private async initializeGeneration(): Promise<void> {
    this.generation_id = `${this.posthog.get_distinct_id()}_${Date.now()}`;
    await this.editorRef.current?.initializeDocument();
    this.editorRef.current?.cleanupPreviousContent();
    this.cancelRef.current = false;
  }

  private async handleOpenAIResponse(
    prompt: string,
    systemPrompt: string,
    template: string
  ): Promise<{ success: boolean; message: string }> {
    if (!systemPrompt.includes('Sprache:')) {
      systemPrompt = `Schreibe deine Antwort in folgender Sprache: German.\n${systemPrompt}`;
    }

    const getopenai = httpsCallable(functions, "getopenai_py", { timeout: 300000 });
    let lastResponse = '';
    let hasReceivedInitialResponse = false;
    let timeoutId: NodeJS.Timeout | null = null;
    let unsubscribe: () => void = () => { };

    try {
      const timeoutPromise = new Promise<never>((_, reject) => {
        timeoutId = setTimeout(() => {
          if (!hasReceivedInitialResponse) {
            reject(new Error("Timeout: No response received after 4 seconds"));
          }
        }, 4000);
      });

      unsubscribe = onSnapshot(
        doc(firestore, "users", this.userId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();

            if (userData.currentlywriting) {
              const currentResponse = userData.currentresponse || '';

              if (currentResponse && !hasReceivedInitialResponse) {
                hasReceivedInitialResponse = true;
                if (timeoutId) clearTimeout(timeoutId);
              }

              if (currentResponse && currentResponse !== lastResponse) {
                const newContent = currentResponse.slice(lastResponse.length);
                if (newContent) {
                  this.handleTextInsertion(newContent);
                  lastResponse = currentResponse;
                }
              }
            } else if (hasReceivedInitialResponse && !userData.currentlywriting) {
              this.editorRef.current?.finalizeMarkdown();
              unsubscribe();
            }
          }
        }
      );

      const result = await Promise.race([
        getopenai({
          prompt,
          systemPrompt,
          template,
          generation_id: this.generation_id,
        }),
        timeoutPromise,
      ]) as { data: { success: boolean; message: string } };

      return result.data;
    } catch (error) {
      unsubscribe();
      if (timeoutId) clearTimeout(timeoutId);
      throw error;
    }
  }

  private async handleGeminiResponse(
    prompt: string,
    systemPrompt: string,
    shouldDecrement: boolean = true
  ): Promise<void> {
    if (!systemPrompt.includes('Sprache:')) {
      systemPrompt = `Schreibe deine Antwort in folgender Sprache: German.\n${systemPrompt}`;
    }

    try {
      const request = [
        { text: systemPrompt },
        { text: prompt }
      ];

      const result = await gemini_model.generateContentStream(request);

      for await (const chunk of result.stream) {
        if (this.cancelRef.current) break;
        this.handleTextInsertion(chunk.text());
      }

      this.editorRef.current?.finalizeMarkdown();

      if (shouldDecrement) {
        this.cancelRef.current = true;
        await decrementCreations(this.userId);
      }
    } catch (error) {
      this.handleGeminiError(error);
    }
  }

  private handleTextInsertion(text: string): void {
    if (text.includes('\n')) {
      const parts = text.split(/(\n)/);
      for (const part of parts) {
        this.editorRef.current?.insertText(part);
      }
    } else {
      this.editorRef.current?.insertText(text);
    }
  }

  private handleGeminiError(error: unknown): void {
    if (error instanceof Error && error.message.includes('429')) {
      Cookies.remove('useFallback');
    }

    toast.error(
      "Fehler beim Generieren des Textes. Bitte versuchen Sie es erneut.",
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  }

  public async generate({
    prompt,
    systemPrompt,
    template,
    shouldDecrement = true
  }: GenerationParams): Promise<void> {
    await this.initializeGeneration();

    this.posthog.capture('template_detail_page:start_generation', {
      generation_id: this.generation_id,
      template,
      prompt,
      system_prompt: systemPrompt,
    });

    try {
      if (Cookies.get('useFallback') === 'true') {
        throw new Error("Fallback Cookie set, using Gemini");
      }

      const response = await this.handleOpenAIResponse(prompt, systemPrompt, template);

      if (!response.success) {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('OpenAI generation failed, falling back to Gemini:', error);
      
      if (window.location.hostname !== 'localhost' && 
          window.location.hostname !== 'tst-robowriter.web.app') {
        const sendSlackMessage = httpsCallable(functions, "send_slack_alert");
        await sendSlackMessage({
          message: `Error in OpenAI Generation, falling back to Gemini. Error: ${error instanceof Error ? error.message : 'Unknown error'}`,
          generation_id: this.generation_id,
          url: window.location.href
        });
      }

      Cookies.set('useFallback', 'true', { expires: 1 / 96 });
      await this.handleGeminiResponse(prompt, systemPrompt, shouldDecrement);
    }
  }

  public cancel(): void {
    this.cancelRef.current = true;
    // Additional logic to stop any ongoing generation process
    // For example, you might want to clear any ongoing network requests or listeners
  }

  public async modifyText(
    text: string,
    prompt: string,
    systemPrompt: string,
    onTextGenerated: (text: string) => void
  ): Promise<void> {
    try {
      this.generation_id = `${this.posthog.get_distinct_id()}_${Date.now()}`;
      this.cancelRef.current = false;

      const originalInsertText = this.editorRef.current?.insertText;
      
      if (this.editorRef.current) {
        this.editorRef.current.insertText = onTextGenerated;
      }

      await this.generate({
        prompt: `${prompt}:\n\n${text}`,
        systemPrompt,
        template: "quick-action",
        shouldDecrement: false,
      });

      if (this.editorRef.current && originalInsertText) {
        this.editorRef.current.insertText = originalInsertText;
      }
    } catch (error) {
      console.error('Error modifying text:', error);
      throw error;
    }
  }

  public async streamModifyText(
    inputText: string,
    prompt: string,
    onChunk: (text: string) => void
  ): Promise<void> {
    try {
      this.generation_id = `${this.posthog.get_distinct_id()}_${Date.now()}`;
      this.cancelRef.current = false;

      const request = [
        { text: prompt },
        { text: inputText }
      ];

      console.log('request', request);

      const result = await modify_model.generateContentStream(request);

      for await (const chunk of result.stream) {
        if (this.cancelRef.current) break;
        onChunk(chunk.text());
      }
    } catch (error) {
      console.error('Error in Gemini text generation:', error);
      this.handleGeminiError(error);
      throw error;
    }
  }

  /**
   * Continues writing from the given paragraph content
   * @param paragraphContent The content of the current paragraph to continue from
   */
  async continueWriting(paragraphContent: string): Promise<void> {
    if (!this.editorRef?.current) {
      console.error('Editor ref is not available:', { 
        hasRef: !!this.editorRef,
        hasCurrent: this.editorRef?.current
      });
      return;
    }

    try {
      // Track the event
      this.posthog?.capture('editor:continue_writing', {
        content_length: paragraphContent.length
      });

      // Get the system prompt for continuation
      const systemPrompt = `Du bist ein hilfreicher Assistent, der den Text des Nutzers fortsetzt.
        Schreibe in einem ähnlichen Stil weiter und behalte den Kontext bei.
        Schreibe deine Antwort in folgender Sprache: German.`;

      // Call the AI generation
      await this.handleGeminiResponse(
        paragraphContent,
        systemPrompt,
        false // Don't decrement credits for continue writing
      );

    } catch (error) {
      console.error('Error in continueWriting:', error);
      throw error;
    }
  }

  public async insertStreamingText(
    editor: TinyMCEEditor,
    text: string,
    insertionPoint?: Node,
    options: {
      appendNewlines?: boolean;
      storeInitialContent?: boolean;
    } = {}
  ) {
    const {
      appendNewlines = true,
      storeInitialContent = true,
    } = options;

    if (!editor || text === undefined || text === null || text === 'undefined') {
      return;
    }

    // Store initial content if needed
    if (storeInitialContent) {
      this.previousContent = editor.getContent();
      if (appendNewlines) {
        if (insertionPoint) {
          editor.selection.select(insertionPoint);
          editor.selection.collapse(false);
          editor.selection.setContent('\n\n');
        } else {
          editor.setContent(this.previousContent + '\n\n');
        }
      }
    }

    // Count newlines before adding new text
    const previousNewlines = (this.completeResponse.match(/\n/g) || []).length;
    
    // Append to complete response for markdown conversion
    this.completeResponse += text;
    
    // Count newlines after adding new text
    const currentNewlines = (this.completeResponse.match(/\n/g) || []).length;
    
    // If number of newlines increased or we have accumulated enough text, finalize markdown
    if (currentNewlines > previousNewlines) {
      await this.finalizeMarkdown(editor);
      return;
    }

    // Move cursor to insertion point or end of document for next insertion
    if (insertionPoint) {
      editor.selection.select(insertionPoint);
      editor.selection.collapse(false);
    } else {
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    }

    // Scroll to bottom
    const container = editor.getContainer();
    if (container) {
      container.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  public async finalizeMarkdown(editor: TinyMCEEditor) {
    if (!this.completeResponse) return;

    try {
      // Convert markdown to HTML
      const finalHtml = this.mdParser.render(this.completeResponse);

      if (this.previousContent) {
        editor.setContent(this.previousContent + '\n\n' + finalHtml);
      } else {
        editor.selection.setContent(finalHtml);
      }

      // Move cursor to end
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);

      // Reset for next generation
      this.completeResponse = '';
      this.previousContent = null;
    } catch (error) {
      console.error('Error converting markdown to HTML:', error);
    }
  }
} 