import * as React from "react"
import { usePostHog } from 'posthog-js/react'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command"
import { Card, CardContent, CardFooter } from "../ui/card"
import { Button } from "../ui/button"
import { ScrollArea, ScrollBar } from "../ui/scroll-area"
import { 
  Wand2, 
  ArrowDown, 
  Replace, 
  SpellCheck,
  ChevronRight,
  ArrowLeft,
  Expand,
  Shrink,
  FileText,
  RefreshCw,
  ChevronDown,
  CornerDownLeft,
  MessageSquare,
  Languages,
  GripVertical,
  Loader2,
  LucideIcon,
  FlipHorizontal
} from "lucide-react"
import { PersonaIconSidebar } from "../../assets/Icons"
import { useAIService } from "../../services/AIServiceContext"
import { Editor as TinyMCEEditor } from 'tinymce'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "../ui/dropdown-menu"
import { useUserData } from "../../providers/UserDataProvider"
import { useNavigate } from "react-router-dom"
import { diffWords } from 'diff'

interface ContextMenuProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  editor: TinyMCEEditor
  position: {
    selectionRect: DOMRect | null
    editorRect: DOMRect | null
  }
}

type MenuState = 'command' | 'response'

interface Action {
  label: string;
  prompt: string;
  icon: LucideIcon;
  subActions?: Action[];
}

// Create a wrapper component that matches LucideIcon type
const PersonaIcon = React.forwardRef<SVGSVGElement>((props, ref) => (
  <div className="mr-2 h-4 w-4">
    <PersonaIconSidebar />
  </div>
));

PersonaIcon.displayName = 'PersonaIcon';

const predefinedActions: Action[] = [
  {
    label: "Tonfall anwenden",
    prompt: "",
    icon: PersonaIcon,
    subActions: [],
  },
  {
    label: "Stil ändern",
    prompt: "",
    icon: MessageSquare,
    subActions: [
      {
        label: "Casual",
        prompt: "Formuliere den zu modifizierenden Text in einem lockeren, entspannten Tonfall um.",
        icon: MessageSquare,
      },
      {
        label: "Konversationell",
        prompt: "Formuliere den zu modifizierenden Text in einem natürlichen, gesprächigen Tonfall um.",
        icon: MessageSquare,
      },
      {
        label: "Professionell",
        prompt: "Formuliere den zu modifizierenden Text in einem professionellen, geschäftlichen Tonfall um.",
        icon: MessageSquare,
      },
      {
        label: "Akademisch",
        prompt: "Formuliere den zu modifizierenden Text in einem akademischen, wissenschaftlichen Tonfall um.",
        icon: MessageSquare,
      },
      {
        label: "Enthusiastisch",
        prompt: "Formuliere den zu modifizierenden Text in einem energetischen, begeisterten Tonfall um.",
        icon: MessageSquare,
      },
    ],
  },
  {
    label: "Übersetzen",
    prompt: "",
    icon: Languages,
    subActions: [
      {
        label: "Englisch",
        prompt: "Übersetze den zu modifizierenden Text ins Englische. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
      {
        label: "Deutsch",
        prompt: "Übersetze den zu modifizierenden Text ins Deutsche. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
      {
        label: "Spanisch",
        prompt: "Übersetze den zu modifizierenden Text ins Spanische. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
      {
        label: "Französisch",
        prompt: "Übersetze den zu modifizierenden Text ins Französische. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
      {
        label: "Italienisch",
        prompt: "Übersetze den zu modifizierenden Text ins Italienische. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
      {
        label: "Portugiesisch",
        prompt: "Übersetze den zu modifizierenden Text ins Portugiesische. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
      {
        label: "Niederländisch",
        prompt: "Übersetze den zu modifizierenden Text ins Niederländische. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
      {
        label: "Polnisch",
        prompt: "Übersetze den zu modifizierenden Text ins Polnische. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
      {
        label: "Türkisch",
        prompt: "Übersetze den zu modifizierenden Text ins Türkische. Behalte dabei den Stil und Tonfall bei.",
        icon: Languages,
      },
    ],
  },
  {
    label: "Länge anpassen",
    prompt: "",
    icon: Expand,
    subActions: [
      {
        label: "Länger machen",
        prompt: "Erweitere den zu modifizierenden Text mit zusätzlichen Details und Erklärungen.",
        icon: Expand,
      },
      {
        label: "Kürzer machen",
        prompt: "Kürze den zu modifizierenden Text, behalte aber die wichtigsten Informationen bei.",
        icon: Shrink,
      },
      {
        label: "Zusammenfassen",
        prompt: "Fasse den zu modifizierenden Text in wenigen Sätzen zusammen.",
        icon: FileText,
      },
    ],
  },
  {
    label: "Verbessern",
    prompt: `Verbessere den zu modifizierenden Text unter Berücksichtigung folgender Richtlinien:

1. Verwende vollständige Sätze und vermeide Aufzählungen.
2. Nutze Fettschrift für wichtige Fachbegriffe und Terminologie.
3. Verwende präzise Fachbegriffe statt allgemeiner Ausdrücke.
4. Formuliere konzise und vermeide überflüssige Ausschmückungen.
5. Behalte einen hohen Informationsgehalt pro Wort bei.
6. Behalte die ursprüngliche Länge weitestgehend bei.
7. Füge bei HTML-Formatierung für bessere Lesbarkeit hinzu.`,
    icon: Wand2,
  },
  {
    label: "Rechtschreibung korrigieren",
    prompt: "Korrigiere Rechtschreibung und Grammatik im zu modifizierenden Text",
    icon: SpellCheck,
  },
]

const cleanContent = (html: string): string => {
  // Create a temporary div to parse HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html.trim();

  // Function to clean a node's text content
  const cleanNode = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      // Only trim text nodes that aren't next to inline formatting elements
      const prevSibling = node.previousSibling;
      const nextSibling = node.nextSibling;
      const isNextToFormatting = (
        prevSibling?.nodeType === Node.ELEMENT_NODE && 
        ['STRONG', 'EM', 'B', 'I', 'SPAN'].includes(prevSibling.nodeName)
      ) || (
        nextSibling?.nodeType === Node.ELEMENT_NODE && 
        ['STRONG', 'EM', 'B', 'I', 'SPAN'].includes(nextSibling.nodeName)
      );

      if (!isNextToFormatting) {
        // Replace multiple spaces and newlines with a single space
        node.textContent = node.textContent?.replace(/\s+/g, ' ').trim() || '';
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // Remove empty paragraphs and divs
      if (['P', 'DIV'].includes(node.nodeName) && !node.textContent?.trim()) {
        node.parentNode?.removeChild(node);
        return;
      }

      // Clean all child nodes
      Array.from(node.childNodes).forEach(cleanNode);

      // Remove empty text nodes at the start and end of block elements
      if (['P', 'DIV'].includes(node.nodeName)) {
        const firstChild = node.firstChild;
        const lastChild = node.lastChild;
        
        if (firstChild?.nodeType === Node.TEXT_NODE && !firstChild.textContent?.trim()) {
          node.removeChild(firstChild);
        }
        if (lastChild?.nodeType === Node.TEXT_NODE && !lastChild.textContent?.trim()) {
          node.removeChild(lastChild);
        }
      }
    }
  };

  // Clean all nodes
  cleanNode(tempDiv);

  // Get the cleaned HTML
  let cleanHtml = tempDiv.innerHTML
    .replace(/\n/g, '') // Remove all newlines
    .replace(/<p>\s*<\/p>/g, '') // Remove empty paragraphs
    .replace(/>\s+</g, '><') // Remove whitespace between tags
    .trim();

  // If content is just text (no HTML), wrap it in a paragraph
  if (!cleanHtml.match(/<[^>]*>/)) {
    return `<p>${cleanHtml}</p>`;
  }

  return cleanHtml;
};

export function ContextMenu({
  open,
  onOpenChange,
  editor,
  position: { selectionRect, editorRect }
}: ContextMenuProps) {
  const aiService = useAIService()
  const navigate = useNavigate()
  const posthog = usePostHog()
  const { personas } = useUserData()
  const menuRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [menuState, setMenuState] = React.useState<MenuState>('command')
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [currentAction, setCurrentAction] = React.useState<Action | null>(null)
  const [streamedResponse, setStreamedResponse] = React.useState('')
  const [inputValue, setInputValue] = React.useState('')
  const [currentActions, setCurrentActions] = React.useState<Action[]>(predefinedActions)
  const [menuHistory, setMenuHistory] = React.useState<Action[][]>([predefinedActions])
  const [cardHeight, setCardHeight] = React.useState(300) // Default card height
  const resizeRef = React.useRef<{
    startY: number;
    startHeight: number;
    minHeight: number;
    maxHeight: number;
  } | null>(null)
  const [isLoadingPersonas, setIsLoadingPersonas] = React.useState(true)
  const [showDiff, setShowDiff] = React.useState(false)

  // Focus input when menu opens
  React.useEffect(() => {
    if (open && menuState === 'command') {
      // Small delay to ensure DOM is ready
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
      
      // Track menu opening
      posthog.capture('context_menu:open')
    }
  }, [open, menuState, posthog])

  // Handle clicks outside the menu
  React.useEffect(() => {
    if (!open) return

    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is inside any dropdown menu
      const isDropdownClick = (event.target as Element)?.closest('[role="menu"]')
      if (isDropdownClick) return

      // Check if click is outside the main menu
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        handleDismiss()
      }
    }

    const handleIframeClick = () => {
      handleDismiss()
    }

    document.addEventListener('mousedown', handleClickOutside)
    
    const iframe = editor.getContainer().querySelector('.tox-edit-area__iframe') as HTMLIFrameElement
    if (iframe?.contentDocument) {
      iframe.contentDocument.addEventListener('mousedown', handleIframeClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (iframe?.contentDocument) {
        iframe.contentDocument.removeEventListener('mousedown', handleIframeClick)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, onOpenChange, editor])

  // Add keyboard shortcut handler
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Check if text is selected
      const selection = editor.selection.getContent();
      if (!selection) return;

      // Check for Ctrl+E (or Cmd+E on Mac)
      if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'e') {
        e.preventDefault(); // Prevent default browser behavior
        onOpenChange(true);
      }
    };

    // Add event listener to the editor's iframe
    const iframe = editor.getContainer().querySelector('.tox-edit-area__iframe') as HTMLIFrameElement;
    if (iframe?.contentDocument) {
      iframe.contentDocument.addEventListener('keydown', handleKeyDown);
    }

    // Add event listener to the main document
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      if (iframe?.contentDocument) {
        iframe.contentDocument.removeEventListener('keydown', handleKeyDown);
      }
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [editor, onOpenChange]);

  const handleAction = async (action: Action) => {
    const selection = editor.selection.getContent()
    const documentText = editor.getContent()
    
    if (!selection || isProcessing || !aiService) return

    // Track action usage
    posthog.capture('context_menu:use_action', {
      action_label: action.label
    })

    setIsProcessing(true)
    setCurrentAction(action)
    setMenuState('response')
    setStreamedResponse('')

    try {
      let accumulatedContent = '';

      let inputText = `Hier ist das gesamte Dokument, sowie der Text, den du anpassen sollst:
        Kontext (vollständiges Dokument):
        ${documentText}

        ---
        
        Text, der modifiziert werden soll. Die Sprache deines Outputs MUSS identisch mit der Sprache des folgenden Textes sein.:
        ${selection}
      `

      await aiService.streamModifyText(
        inputText,
        action.prompt,
        (chunk: string) => {
          // If we have no content yet and the chunk is just whitespace/newlines, skip it
          if (!accumulatedContent && chunk.trim() === '') {
            return;
          }

          // Only clean whitespace between HTML tags, preserve all other whitespace
          const processedChunk = chunk.replace(/>\s+</g, '><');

          // Update both the accumulated content and the displayed response
          accumulatedContent += processedChunk;
          setStreamedResponse(accumulatedContent);
        }
      )
    } catch (error) {
      console.error("Error processing action:", error)
      setStreamedResponse("Fehler: Anfrage konnte nicht verarbeitet werden. Bitte versuchen Sie es erneut.")
      setMenuState('command')
    } finally {
      setIsProcessing(false)
    }
  }

  const handleCustomCommand = async (command: string) => {
    if (!command.trim() || isProcessing) return
    await handleAction({prompt: command, label: "Benutzerdefiniert", icon: Wand2})
    setInputValue('') // Clear input after sending
  }

  const handleActionSelect = (action: Action) => {
    // Special case for "Create Tone" action
    if (action.label === "+ Tonfall erstellen") {
      navigate('/tonfall')
      onOpenChange(false)
      return
    }

    if (action.subActions) {
      // If action has sub-actions, navigate to sub-menu
      const subActions = action.subActions || []
      setMenuHistory(prev => [...prev, subActions])
      setCurrentActions(subActions)
    } else {
      // If action is leaf node, execute it
      handleAction(action)
    }
  }

  const handleBack = () => {
    if (menuHistory.length <= 1) return; // Don't go back if we're at the root
    
    const newHistory = menuHistory.slice(0, -1);
    setCurrentActions(newHistory[newHistory.length - 1]);
    setMenuHistory(newHistory);
  }

  const handleReplace = () => {
    editor.undoManager.transact(() => {
      // Clean the content but preserve inline formatting
      const cleanedContent = cleanContent(streamedResponse)
        .replace(/^<p>(.*)<\/p>$/, '$1'); // Remove wrapping p tags if they exist
      
      // Set the content directly in the current selection
      editor.selection.setContent(cleanedContent);
      
      // Normalize the content to clean up any artifacts
      editor.selection.normalize();
    });
    
    // Track text replacement
    posthog.capture('context_menu:replace')
    
    editor.undoManager.add();
    handleDismiss();
  };

  const handleInsertBelow = () => {
    if (!editor || !streamedResponse) return;

    editor.undoManager.transact(() => {
      // Get the current selection content
      const currentContent = editor.selection.getContent();
      
      // Clean the new content but preserve inline formatting
      const cleanedContent = cleanContent(streamedResponse)
        .replace(/^<p>(.*)<\/p>$/, '$1'); // Remove wrapping p tags if they exist
      
      // Combine the current content with the new content
      const combinedContent = currentContent + '<p></p>' + cleanedContent;
      
      // Set the combined content
      editor.selection.setContent(combinedContent);
      
      // Normalize the content to clean up any artifacts
      editor.selection.normalize();
    });
    
    // Track text insertion below
    posthog.capture('context_menu:insert_below')
    
    editor.undoManager.add();
    handleDismiss();
  };

  const handleDismiss = () => {
    onOpenChange(false);
    setMenuState('command');
    setStreamedResponse('');
    setInputValue('');
    setMenuHistory([predefinedActions]);
    setCurrentActions(predefinedActions);
  };

  const handleCopy = async () => {
    try {
      // Create a temporary div to parse HTML and get plain text
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = streamedResponse;
      const plainText = tempDiv.textContent || tempDiv.innerText || '';
      
      await navigator.clipboard.writeText(plainText);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  }

  const handleRefresh = () => {
    if (!currentAction) return

    // Track refresh action
    posthog.capture('context_menu:refresh', {
      action_label: currentAction.label
    })

    handleAction(currentAction)
  }

  const handleResizeStart = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    const card = menuRef.current?.querySelector('.card-content') as HTMLElement
    if (!card) return

    resizeRef.current = {
      startY: e.clientY,
      startHeight: cardHeight,
      minHeight: 200, // Minimum height
      maxHeight: window.innerHeight - 100 // Maximum height (viewport height - 100px buffer)
    }

    document.addEventListener('mousemove', handleResizeMove)
    document.addEventListener('mouseup', handleResizeEnd)
  }

  const handleResizeMove = (e: MouseEvent) => {
    if (!resizeRef.current) return

    const dy = e.clientY - resizeRef.current.startY
    const newHeight = Math.max(
      resizeRef.current.minHeight,
      Math.min(
        resizeRef.current.maxHeight,
        resizeRef.current.startHeight + dy
      )
    )

    setCardHeight(newHeight)
  }

  const handleResizeEnd = () => {
    resizeRef.current = null
    document.removeEventListener('mousemove', handleResizeMove)
    document.removeEventListener('mouseup', handleResizeEnd)
  }

  // Update the preview styles to ensure proper content display
  const previewStyles = `
    .preview-content {
      font-family: inherit;
      min-height: 100%;
      padding: 0.5rem;
    }
    .preview-content ul {
      list-style-type: disc;
      padding-left: 1.5em;
      margin: 0.5em 0;
    }
    .preview-content ol {
      list-style-type: decimal;
      padding-left: 1.5em;
      margin: 0.5em 0;
    }
    .preview-content li {
      margin: 0.25em 0;
    }
    .preview-content p {
      margin: 0.5em 0;
    }
    .preview-content h1, .preview-content h2, .preview-content h3 {
      margin: 0.75em 0 0.5em;
      font-weight: 600;
    }
    .preview-content h1 { font-size: 1.5em; }
    .preview-content h2 { font-size: 1.25em; }
    .preview-content h3 { font-size: 1.1em; }
  `;

  // Add position calculation
  const menuPosition = React.useMemo(() => {
    if (!selectionRect || !editorRect) return null;

    const MENU_MIN_HEIGHT = 300; // Minimum height of the menu
    const MENU_PADDING = 16; // Padding from edges
    const viewportHeight = window.innerHeight;
    
    // Check if selection is very tall
    const isLargeSelection = selectionRect.height > (viewportHeight * 0.6);
    
    // Calculate available spaces
    const spaceBelow = viewportHeight - selectionRect.bottom;
    const spaceAbove = selectionRect.top;
    
    // For large selections, position the menu over the selection
    if (isLargeSelection) {
      const centerY = selectionRect.top + (selectionRect.height / 2);
      
      return {
        top: centerY - (MENU_MIN_HEIGHT / 2),
        left: editorRect.left + MENU_PADDING,
        width: `calc(${editorRect.width}px - ${MENU_PADDING * 2}px)`,
        transformOrigin: 'center',
        maxHeight: `${Math.min(MENU_MIN_HEIGHT * 1.5, viewportHeight - MENU_PADDING * 2)}px`,
      };
    }
    
    // For normal selections, try to position below or above
    const shouldShowBelow = spaceBelow >= MENU_MIN_HEIGHT || spaceBelow > spaceAbove;
    
    return {
      top: shouldShowBelow 
        ? Math.min(selectionRect.bottom + 6, viewportHeight - MENU_MIN_HEIGHT - MENU_PADDING)
        : Math.max(MENU_PADDING, selectionRect.top - MENU_MIN_HEIGHT - 6),
      left: editorRect.left + MENU_PADDING,
      width: `calc(${editorRect.width}px - ${MENU_PADDING * 2}px)`,
      transformOrigin: shouldShowBelow ? 'top' : 'bottom',
      maxHeight: `${MENU_MIN_HEIGHT}px`,
    };
  }, [selectionRect, editorRect]);

  // Update personas in the menu when they load
  React.useEffect(() => {
    const toneAction = predefinedActions.find(action => action.label === "Tonfall anwenden")
    if (toneAction && personas) {
      setIsLoadingPersonas(false)
      
      // Create persona actions
      const personaActions = personas.map(persona => ({
        label: persona.standard ? `★ ${persona.name}` : persona.name,
        prompt: `Formuliere den zu Text im folgenden Tonfall um:\n\n${persona.personatext} \n\nBehalte dabei die Sprache des Originaltextes bei.`,
        icon: PersonaIcon,
      }))

      // Update the subActions
      toneAction.subActions = [
        ...personaActions,
        {
          label: "+ Tonfall erstellen",
          prompt: "",
          icon: PersonaIcon,
        }
      ]

      // Update current actions if we're in the tone submenu
      if (menuHistory.length > 1 && menuHistory[menuHistory.length - 1][0]?.label === "Tonfall anwenden") {
        setCurrentActions(toneAction.subActions || [])
      }
    }
  }, [personas, menuHistory])

  const DiffView = ({ original, modified }: { original: string; modified: string }) => {
    // Convert HTML to plain text for diffing, preserving line breaks
    const getTextContent = (html: string) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      // Replace <p> and <br> tags with newlines before getting text content
      const withLineBreaks = div.innerHTML
        .replace(/<\/p>/g, '\n')
        .replace(/<br\s*\/?>/g, '\n');
      div.innerHTML = withLineBreaks;
      return div.textContent || div.innerText || '';
    };

    const originalText = getTextContent(original);
    const modifiedText = getTextContent(modified);
    
    // Split into lines first
    const originalLines = originalText.split('\n');
    const modifiedLines = modifiedText.split('\n');
    
    // Get the maximum number of lines
    const maxLines = Math.max(originalLines.length, modifiedLines.length);
    
    // Create array of line indices
    const lineIndices = Array.from({ length: maxLines }, (_, i) => i);
    
    // Diff each line separately
    const lineDiffs = lineIndices.map(i => {
      const originalLine = originalLines[i] || '';
      const modifiedLine = modifiedLines[i] || '';
      return diffWords(originalLine, modifiedLine);
    });
    
    return (
      <div className="grid grid-cols-2 gap-4 p-4 font-mono text-sm ml-4">
        {/* Original */}
        <div className="border-r pr-4">
          <div className="font-semibold mb-2 text-gray-500">Original</div>
          <div className="whitespace-pre-wrap">
            {lineIndices.map(lineIndex => {
              const line = originalLines[lineIndex] || '';
              const diff = lineDiffs[lineIndex];
              return (
                <div key={lineIndex} className="min-h-[1.5em]">
                  {diff.map((part, i) => (
                    <span 
                      key={i}
                      className={part.removed ? 'bg-red-100 text-red-900' : 'text-gray-600'}
                    >
                      {part.removed ? part.value : (!part.added ? part.value : '')}
                    </span>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        
        {/* Modified */}
        <div className="pl-4">
          <div className="font-semibold mb-2 text-gray-500">Angepasst</div>
          <div className="whitespace-pre-wrap">
            {lineIndices.map(lineIndex => {
              const line = modifiedLines[lineIndex] || '';
              const diff = lineDiffs[lineIndex];
              return (
                <div key={lineIndex} className="min-h-[1.5em]">
                  {diff.map((part, i) => (
                    <span 
                      key={i}
                      className={part.added ? 'bg-green-100 text-green-900' : 'text-gray-600'}
                    >
                      {part.added ? part.value : (!part.removed ? part.value : '')}
                    </span>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  if (!open || !menuPosition) return null;

  return (
    <div 
      ref={menuRef}
      className="pointer-events-auto absolute"
      style={{
        top: menuPosition.top,
        left: menuPosition.left,
        width: menuPosition.width,
        maxHeight: menuPosition.maxHeight,
        transformOrigin: menuPosition.transformOrigin,
      }}
    >
      <style>{previewStyles}</style>
      {menuState === 'command' ? (
        <Command className="rounded-lg border shadow-md bg-white w-full">
          <CommandInput 
            ref={inputRef}
            placeholder="Gib einen Befehl ein oder wähle eine Aktion..."
            value={inputValue}
            onValueChange={setInputValue}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault()
                handleCustomCommand(inputValue)
              }
            }}
            className="w-full border-none focus:outline-none focus-visible:ring-0 focus:ring-0"
          />
          <CommandList className="w-full">
            <CommandEmpty>
              <div className="flex items-center justify-between w-full px-4 py-1">
                <div className="flex items-center gap-1 text-sm text-muted-foreground">
                  Befehl mit Enter senden
                  <CornerDownLeft className="h-4 w-4" />
                </div>
                <Button
                  variant="default"
                  size="sm"
                  className="px-3"
                  disabled={isProcessing || !inputValue.trim()}
                  onClick={() => handleCustomCommand(inputValue)}
                >
                  Senden
                </Button>
              </div>
            </CommandEmpty>
            <CommandGroup heading={
              menuHistory.length > 1 ? (
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-6 px-2"
                    onClick={handleBack}
                  >
                    <ArrowLeft className="h-4 w-4" />
                  </Button>
                  <span>Zurück</span>
                </div>
              ) : "Schnellaktionen"
            }>
              {currentActions.map((action) => (
                <CommandItem
                  key={action.label}
                  onSelect={() => handleActionSelect(action)}
                  className="cursor-pointer hover:bg-purple-50 transition-colors duration-150 w-full"
                  disabled={isProcessing}
                >
                  {isLoadingPersonas && action.label === "loading" ? (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    <action.icon className="mr-2 h-4 w-4" />
                  )}
                  <span className="flex-grow">{action.label}</span>
                  {action.subActions && (
                    <ChevronRight className="h-4 w-4 text-gray-400" />
                  )}
                  {isProcessing && <span className="ml-2 text-xs text-gray-500">Verarbeite...</span>}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      ) : (
        <Card 
          className="border shadow-md bg-white w-full relative card-content"
          style={{ height: cardHeight }}
        >
          <div className="flex flex-col h-full">
            <CardContent className="flex-grow overflow-hidden pt-4 pb-0 px-4 relative">
              <ScrollArea 
                className="h-full"
              >
                {showDiff ? (
                  <DiffView 
                    original={editor.selection.getContent()}
                    modified={streamedResponse}
                  />
                ) : (
                  <div 
                    className="preview-content prose prose-sm max-w-none pr-4"
                    dangerouslySetInnerHTML={{ __html: streamedResponse }}
                  />
                )}
                <ScrollBar 
                  orientation="vertical"
                />
              </ScrollArea>
            </CardContent>

            <div className="flex-shrink-0">
              <div className="relative">
                <div className="absolute bottom-full left-0 right-0 border-t border-border">
                  <div 
                    className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 h-4 w-8 bg-white cursor-row-resize hover:bg-purple-100 transition-colors flex items-center justify-center"
                    onMouseDown={handleResizeStart}
                  >
                    <GripVertical className="h-4 w-4 text-gray-400 rotate-90" />
                  </div>
                </div>
                <CardFooter className="flex justify-between gap-2 py-3">
                  <div className="hidden md:flex items-center gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowDiff(!showDiff);
                      }}
                      className={`
                        rounded-full h-8 px-3 gap-1.5
                        transition-colors duration-200
                        ${showDiff ? 'bg-purple-50 text-purple-700 border-purple-200 hover:bg-purple-100' : ''}
                      `}
                    >
                      <FlipHorizontal className="h-4 w-4" />
                      Vergleichsansicht
                    </Button>
                  </div>
                  <div className="flex gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleRefresh}
                      disabled={isProcessing}
                    >
                      <RefreshCw className={`h-4 w-4 ${isProcessing ? 'animate-spin' : ''}`} />
                    </Button>
                    <div className="flex">
                      <Button
                        variant="default"
                        size="sm"
                        className="rounded-r-none px-3"
                        disabled={isProcessing}
                        onClick={handleReplace}
                      >
                        <Replace className="h-4 w-4 mr-2" />
                        <span>Ersetzen</span>
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            data-testid="context-menu-dropdown-trigger"
                            variant="default"
                            size="sm"
                            className="rounded-l-none border-l border-primary/20 px-2"
                            disabled={isProcessing}
                          >
                            <ChevronDown className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent 
                          align="end" 
                          side="bottom" 
                          className="bg-white border shadow-md"
                          onClick={(e) => e.stopPropagation()}
                          sideOffset={5}
                        >
                          <DropdownMenuItem 
                            data-testid="context-menu-item-insert-below"
                            className="cursor-pointer hover:bg-purple-50 transition-colors duration-150"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleInsertBelow();
                            }}
                          >
                            <ArrowDown className="h-4 w-4 mr-2" />
                            Darunter einfügen
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                </CardFooter>
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  )
} 