import { getGenerativeModel } from "firebase/vertexai-preview";
import { auth, vertexAI } from '../../config/fbConfig';
import { SchemaType } from "@google/generative-ai";
import firebase from "../../config/fbConfig";
import { toast } from 'react-toastify';
import { NavigateFunction } from 'react-router-dom';
import { UserGuideStepEnum } from "../../consts";

const schema = {
    type: SchemaType.OBJECT,
    properties: {
      name: { type: SchemaType.STRING },
      prompt: { type: SchemaType.STRING },
      placeholders: {
        type: SchemaType.ARRAY,
        items: {
          type: SchemaType.OBJECT,
          properties: {
            label: { type: SchemaType.STRING },
            type: { type: SchemaType.STRING, enum: ["textarea", "input"] },
            suggestions: { type: SchemaType.STRING },
            information: { type: SchemaType.STRING },
          },
          required: ["label", "type", "suggestions", "information"],
        },
      },
      replaceValues: { type: SchemaType.ARRAY, items: { type: SchemaType.STRING } },
      type: { type: SchemaType.STRING },
      title: { type: SchemaType.STRING },
      description: { type: SchemaType.STRING },
    },
    required: ["name", "prompt", "placeholders", "replaceValues", "type", "title", "description"],
  };

export const handleButtonClick = async (
  inputText: string,
  setIsLoading: (loading: boolean) => void,
  navigate: NavigateFunction,
  completeOnBoardingStep?: (step: any) => void,
  posthog?: any
) => {
  setIsLoading(true);
  try {
    if (posthog) {
      posthog.capture('handleButtonClick:template_generated', {
        input_text: inputText
      });
    }

    const model = getGenerativeModel(vertexAI, {
      model: "gemini-1.5-pro-002",
      generationConfig: {
        responseMimeType: "application/json",
        // @ts-ignore
        responseSchema: schema,
      },
    });

    let prompt = `Du bist ein Computerprogramm, welches Vorlagen generiert, entsprechend der Beschreibung des Nutzers.
    Überlege dir Schritt für Schritt, welche Informationen der Nutzer zur Verfügung stellen muss, für ein optimales Ergebnis, 
    und lege für jede Information ein entsprechendes Placeholder-Feld an. Sollte der Nutzer bereits Informationen in seiner Anfrage 
    für ein entsprechendes Feld bereit gestellt haben, inkludiere diese Information in dem "information" Feld des entsprechendenPlaceholder-Feldes.
    Nachdem du Schritt für Schritt durchgegangen bist, welche Eingabefelder relevant sind, sollst du diese abstrahieren und auf maximal drei Eingabefelder beschränken. 
    Orientiere dich am "Schema" Ausgabeformat. Der type kann entweder "textarea" (großes Feld) oder "input" (kleines Feld) sein. 
    Erstelle alle Schema Komponenten in der selben Sprache in der der Nutzer seine Anfrage stellt. 
    Stelle absolut sicher, dass alle schema values auf der Sprache sind, in der der Nutzer seine Eingabe getätigt hat.
    
    Hier sind einige Beispiele:
    
    Beispiele 1:
    Nutzereingabe: Schreib mir eine Kaltakquise E-Mail für mein Frühphasen Startup.
    {
    "description": "Generiert eine Vorlage für eine Kaltakquise-E-Mail für Frühphasen-Startups.",
    "name": "Kaltakquise-E-Mail",
    "placeholders": [
        {
        "information": "",
        "label": "Zielgruppe",
        "suggestions": "Kleine Unternehmen, Mittelständische Unternehmen, Technologie-Startups, Investoren, Privatpersonen",
        "type": "input"
        },
        {
        "information": "",
        "label": "Produktbeschreibung",
        "suggestions": "keine",
        "type": "textarea"
        },
        {
        "information": "",
        "label": "Alleinstellungsmerkmal",
        "suggestions": "keine",
        "type": "textarea"
        }
    ],
    "prompt": "Schreibe eine überzeugende Kaltakquise-E-Mail, die auf die Zielgruppe {Zielgruppe} zugeschnitten ist, das Produkt {Produktbeschreibung} beschreibt und das Alleinstellungsmerkmal {Alleinstellungsmerkmal} hervorhebt.",
    "replaceValues": [
        "Zielgruppe",
        "Produktbeschreibung",
        "Alleinstellungsmerkmal"
    ],
    "title": "Kaltakquise-E-Mail",
    "type": "email_template",
    }

    Beispiel 2:

    Nutzereingabe: Write long-form content that provides value, drives traffic, and enhances SEO
    {
    "description": "Generates a long Blog Article, based on User Input.",
    "name": "Blog Post",
    "placeholders": [
        {
        "information": "",
        "label": "Topic",
        "suggestions": "Sustainable Living Tips for Everyday Life; The Future of Remote Work: Trends and Best Practices; Mental Health in the Digital Age: Strategies for Wellness",
        "type": "input"
        },
        {
        "information": "",
        "label": "Audience",
        "suggestions": "Small Business Owners, Digital Nomads, Entrepreneurs",
        "type": "input"
        },
        {
        "information": "",
        "label": "Outline",
        "suggestions": "Keine",
        "type": "textarea"
        }
    ],
    "prompt": "Schreibe einen Blog Post mit dem Thema {Topic} für die Zielgruppe {Audience} basierend auf folgender Gliederung: {Outline}",
    "replaceValues": [
        "Topic",
        "Audience",
        "Outline"
    ],
    "title": "Blog Post",
    "type": "blog_post"
    }

    Beispiel 3:

    Nutzereingabe: Spark conversations and connect with prospects through strategic cold emails
    {
    "description": "Spark conversations and connect with prospects through strategic cold emails",
    "name": "Cold Email",
    "placeholders": [
        {
        "information": "",
        "label": "Audience",
        "suggestions": "Small Business Owners, Tech Startups, Marketing Professionals",
        "type": "input"
        },
        {
        "information": "",
        "label": "Pain points",
        "suggestions": "Keine",
        "type": "textarea"
        },
        {
        "information": "",
        "label": "Benefits",
        "suggestions": "Keine",
        "type": "textarea"
        },
        {
        "information": "",
        "label": "Call to action",
        "suggestions": "Schedule a discovery call today; Respond with your availability for a meeting; Sign up for our exclusive webinar series",
        "type": "input"
        }
    ],
    "prompt": "Erstelle eine Cold Email, die die folgenden Punkte berücksichtigt:\n\nZielgruppe: {Audience}\nSchmerzpunkte: {Pain points}\nVorteile: {Benefits}\nCall to action: {Call to action}",
    "replaceValues": [
        "Audience",
        "Pain points",
        "Benefits",
        "Call to action"
    ],
    "title": "Cold Email",
    "type": "email_template",
    }
    `;

    const result = await model.generateContent(prompt + "\n\nNutzereingabe:\n" + inputText);
    const responseJson = JSON.parse(result.response.text());
    responseJson.iconName = 'fas fa-regular fa-star'
    responseJson.iconColor = '#FEA638'
    responseJson.type = 'Persönliche Vorlagen'

    const userDoc = firebase.firestore().collection("users").doc(auth?.currentUser?.uid);
    const templateId = Math.random().toString(36).substring(2, 22);
    const newTemplate = {
      template_data: responseJson,
      public: false,
      UserRequest: inputText,
      CreatedAt: firebase.firestore.Timestamp.now(),
      LastEdited: firebase.firestore.Timestamp.now(),
      disableLink: false
    };

    await userDoc.collection("templates").doc(templateId).set(newTemplate);

    const templateDoc = await userDoc.collection("templates").doc(templateId).get();
    if (templateDoc.exists && templateDoc.data()) {
      const newTemplatePath = `/templates/${templateDoc.data()?.template_data.name?.replace(/\s+/g, '-').toLowerCase()}`;
      navigate(newTemplatePath, { state: responseJson });
    } else {
      console.error('Unexpected Error. No template found with ID:', templateId);
      toast.error("An unexpected error occurred. Please try again shortly.", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (completeOnBoardingStep) {
      completeOnBoardingStep(UserGuideStepEnum.TEMPLATE)
    };

  } catch (error) {
    console.error('Error:', error);
    alert('An error occurred while processing your request.');
  } finally {
    setIsLoading(false);
  }
};

export const handleTemplateEdit = async (
  existingTemplate: any,
  editCommand: string,
  setIsLoading: (loading: boolean) => void,
  navigate: NavigateFunction,
  posthog?: any
) => {
  setIsLoading(true);
  try {
    if (posthog) {
      posthog.capture('handleTemplateEdit:template_edited', {
        template_id: existingTemplate.id,
        edit_command: editCommand
      });
    }

    const model = getGenerativeModel(vertexAI, {
      model: "gemini-1.5-pro-002",
      generationConfig: {
        responseMimeType: "application/json",
        // @ts-ignore
        responseSchema: schema
        },
    });

    const prompt = `Du bist ein Computerprogramm, welches bestehende Vorlagen anpasst. Dir wird eine bestehende Vorlage und ein Änderungswunsch übergeben.
    Passe die bestehende Vorlage entsprechend des Änderungswunsches an. Behalte dabei die grundlegende Struktur bei. Stelle sicher, dass name immer gleich titel ist!
    Stelle sicher, dass die Ausgabe dem vorgegebenen Schema entspricht und in der gleichen Sprache wie die Eingabevorlage ist.
    
    Bestehende Vorlage:
    ${JSON.stringify(existingTemplate, null, 2)}
    
    Änderungswunsch:
    ${editCommand}`;

    
    const result = await model.generateContent(prompt);
    const responseJson = JSON.parse(result.response.text());
    responseJson.title = responseJson.name
    responseJson.iconName = 'fas fa-regular fa-star'
    responseJson.iconColor = '#FEA638'
    responseJson.type = 'Persönliche Vorlagen'
    const templateId = existingTemplate.id;
    responseJson.id = templateId;
    const userDoc = firebase.firestore().collection("users").doc(auth?.currentUser?.uid);

    const updatedTemplate = {
      template_data: responseJson,
      public: false,
      UserRequest: editCommand,
      LastEdited: firebase.firestore.Timestamp.now(),
    };

    await userDoc.collection("templates").doc(templateId).update(updatedTemplate);


  } catch (error) {
    console.error('Error:', error);
    toast.error("Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.", {
      position: "top-right",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } finally {
    setIsLoading(false);
  }
};
