import * as React from "react"
import { Button } from "../ui/button"
import { Editor as TinyMCEEditor } from 'tinymce'
import { ContextMenu } from "./ContextMenu"
import { useSelectionPosition } from "./hooks/useSelectionPosition"

interface ContextButtonProps {
  editor: TinyMCEEditor
}

const SHOW_ONLY_ON_SELECTION = true

export function ContextButton({ editor }: ContextButtonProps) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const { selectionRect, editorRect } = useSelectionPosition(editor)

  const shouldShowButton = React.useMemo(() => {
    if (SHOW_ONLY_ON_SELECTION) {
      const selection = editor.selection.getContent()
      return !!selection && !!selectionRect
    }
    return !!selectionRect
  }, [editor, selectionRect])

  if (!shouldShowButton || !selectionRect) return null

  return (
    <div className="pointer-events-none fixed inset-0 z-50">
      <div 
        className="pointer-events-auto absolute"
        style={{
          top: selectionRect.bottom + 6,
          left: selectionRect.left,
        }}
      >
        <Button
          size="sm"
          variant="outline"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setIsMenuOpen(true)
          }}
          className="h-12 px-4 bg-white hover:bg-purple-50 border-grey hover:border-purple-700 flex flex-col items-center py-1"
        >
          <div className="flex items-center gap-2">
            <img 
              src="/logo192.png" 
              alt="Logo" 
              className="w-5 h-5 object-contain"
            />
            <span className="text-base font-medium text-gray-900">Anpassen</span>
          </div>
          <span className="text-[9px] text-gray-500 font-normal leading-none mt-0">strg + e</span>
        </Button>
      </div>
      <ContextMenu
        open={isMenuOpen}
        onOpenChange={setIsMenuOpen}
        editor={editor}
        position={{ selectionRect, editorRect }}
      />
    </div>
  )
} 