import { collection, deleteDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { firestore } from '../../config/fbConfig';
import templates from '../../data/templates.json';

export interface Template {
  id: string;
  title: string;
  description: string;
  type: string;
  prompt: string;
  placeholders: Array<{
    label: string;
    type: string;
    description: string;
  }>;
  replaceValues: string[];
  favorite?: boolean;
}

export class TemplateManager {
  private userId: string;
  private templateId: string;

  constructor(userId: string, templateId?: string) {
    this.userId = userId;
    this.templateId = templateId || '';
  }

  /**
   * Get all templates (both from JSON and database)
   */
  public async getAllTemplates(): Promise<Template[]> {
    const userTemplates = await this.getUserTemplates();
    return [...userTemplates, ...(templates as Template[])];
  }

  /**
   * Get user-specific templates from Firestore
   */
  private async getUserTemplates(): Promise<Template[]> {
    try {
      const templatesRef = collection(firestore, "users", this.userId, "templates");
      const querySnapshot = await getDocs(templatesRef);
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data().template_data
      } as Template));
    } catch (error) {
      console.error("Error fetching user templates:", error);
      return [];
    }
  }

  /**
   * Get a specific template by ID
   */
  public async getTemplateById(): Promise<Template | null> {
    if (!this.templateId) return null;

    // First check JSON templates
    const jsonTemplate = templates.find(t => t.id === this.templateId) as Template;
    if (jsonTemplate) return jsonTemplate;

    // Then check user templates in Firestore
    try {
      const templateRef = doc(firestore, "users", this.userId, "templates", this.templateId);
      const templateDoc = await getDoc(templateRef);
      
      if (templateDoc.exists()) {
        return {
          id: templateDoc.id,
          ...templateDoc.data().template_data
        } as Template;
      }
      
      return null;
    } catch (error) {
      console.error("Error fetching template:", error);
      return null;
    }
  }

  /**
   * Delete a template
   */
  public async delete(): Promise<boolean> {
    if (!this.templateId) return false;

    try {
      // Check if template exists in JSON (cannot delete these)
      const jsonTemplate = templates.find(t => t.id === this.templateId);
      if (jsonTemplate) {
        console.error("Cannot delete built-in templates");
        return false;
      }

      // Delete from Firestore
      const templateRef = doc(firestore, "users", this.userId, "templates", this.templateId);
      await deleteDoc(templateRef);
      return true;
    } catch (error) {
      console.error("Error deleting template:", error);
      return false;
    }
  }

  /**
   * Get a template by title
   * @param title The title of the template to find
   * @returns The first matching template or null if not found
   */
  public async getTemplateByName(title: string): Promise<Template | null> {
    // First check JSON templates
    const jsonTemplate = templates.find(t => t.title.replace(/ /g, "-") === title) as Template;
    if (jsonTemplate) return jsonTemplate;
    // Then check user templates in Firestore
    try {
      const templatesRef = collection(firestore, "users", this.userId, "templates");
      const querySnapshot = await getDocs(templatesRef);
      
      const matchingTemplate = querySnapshot.docs.find(doc => 
        doc.data().template_data.title.replace(/ /g, "-") === title
      );

      if (matchingTemplate) {
        return {
          id: matchingTemplate.id,
          ...matchingTemplate.data().template_data
        } as Template;
      }
      
      return null;
    } catch (error) {
      console.error("Error fetching template by name:", error);
      return null;
    }
  }
} 