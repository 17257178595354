import React, { createContext, useContext, useRef, useEffect, useMemo } from 'react'
import { AIService } from './AIService'
import type { DocumentEditorRef } from '../components/editor/DocumentEditor'
import { usePostHog } from 'posthog-js/react'

interface AIServiceContextValue {
  aiService: AIService | null
}

const AIServiceContext = createContext<AIServiceContextValue>({ aiService: null })

interface AIServiceProviderProps {
  children: React.ReactNode
  userId: string
  editorRef: React.RefObject<DocumentEditorRef>
}

export function AIServiceProvider({ children, userId, editorRef }: AIServiceProviderProps) {
  const posthog = usePostHog()
  const aiServiceRef = useRef<AIService | null>(null)

  // Initialize the service only once
  useEffect(() => {
    if (!aiServiceRef.current) {
      aiServiceRef.current = new AIService({
        editorRef,
        userId,
        posthog
      });
    }
  }, [userId, posthog]);

  // Update the service's editorRef if it changes
  useEffect(() => {
    if (aiServiceRef.current) {
      aiServiceRef.current.updateEditorRef(editorRef);
    }
  }, [editorRef]);

  // Memoize the context value based on the ref's current value
  const contextValue = useMemo(() => ({
    aiService: aiServiceRef.current
  }), [aiServiceRef.current]);

  return (
    <AIServiceContext.Provider value={contextValue}>
      {children}
    </AIServiceContext.Provider>
  );
}

export function useAIService() {
  const context = useContext(AIServiceContext);

  if (context === undefined) {
    throw new Error('useAIService must be used within an AIServiceProvider');
  }

  return context.aiService;
}