import { ArrowLeft } from "lucide-react";
import { Button } from "../ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";
import { InputCard } from "../cards/InputCard";
import { motion } from "framer-motion";

const SkeletonTemplatePage = () => {
  return (
    <form className="animate-pulse pointer-events-none">
      {/* Main content area */}
      <div className="flex-grow flex flex-col lg:flex-row overflow-hidden">
        {/* Input Column */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: -30 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.3,
                when: "beforeChildren",
                delayChildren: 0.1, 
                staggerChildren: 0.2, 
              },
            },
          }}
          className="w-full lg:w-1/3 flex-shrink-0 block"
        >
          <div className="h-full overflow-y-auto custom-scrollbar mx-4 mt-4 pb-20 lg:pb-4">
            {/* Header Card Animation */}
            <motion.div variants={{ hidden: { opacity: 0, y: -20 }, visible: { opacity: 1, y: 0 } }}>
              <Card className="border-gray-200 shadow-none sticky top-0 bg-white">
                <CardHeader className="flex items-left">
                  <div className="flex flex-col">
                    <div className="flex items-center mb-2">
                      <div className="lg:hidden mr-4">
                        <Button variant="ghost" className="p-0">
                          <ArrowLeft className="h-6 w-6" />
                        </Button>
                      </div>
                      <CardTitle className="w-full h-4">
                        <p className="w-8/12 bg-gray-100 h-6 rounded"></p>
                      </CardTitle>
                    </div>
                    <CardDescription className="mt-2 w-full h-4">
                      <p className="w-10/12 bg-gray-100 h-6 rounded"></p>
                    </CardDescription>
                  </div>
                </CardHeader>
                <CardContent>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {renderDropdowns()}
                  </ul>
                </CardContent>
              </Card>
            </motion.div>

            {/* Divider */}
            <motion.div
              className="h-px w-full bg-gray-200 my-4"
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
            ></motion.div>

            {/* Render Inputs with Animation */}
            <motion.div>
              {renderInputs()}
            </motion.div>

            {/* Divider */}
            <motion.div
              className="h-px w-full bg-gray-200 my-4"
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
            ></motion.div>

            {/* Start Button */}
            <motion.div
              className={`flex justify-center mt-auto px-4 fixed bottom-0 left-0 right-0 lg:static lg:mt-4 pb-4`}
              variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
            >
              <Button type="submit" variant={"secondary"} className="w-full lg:w-auto lg:min-w-[200px]">
                {/* <p className="w-8/12 bg-gray-100 h-6 rounded"></p> */}
              </Button>
            </motion.div>
          </div>
        </motion.div>

        {/* Output Column with Animation */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className="w-full lg:w-2/3 flex flex-col flex-grow overflow-hidden"
        >
          <div className="lg:pt-4 lg:flex-grow flex flex-col h-[calc(100vh-100px)] lg:h-full overflow-y-auto">
            {/* Skeleton for DocumentEditor */}
            <div className="flex flex-col h-full relative">
              <div className="flex items-center justify-between px-2 h-12 border rounded-t-lg border-gray-200 bg-white relative">
                <div className="text-xl font-medium border-none flex-grow mr-4 bg-gray-100 h-6 rounded"></div>
                <div className="flex items-center text-gray-500">
                  <div className="w-5 h-5 bg-gray-100 rounded-full"></div>
                </div>
              </div>

              <div className="flex-grow relative -mt-[2px] bg-white rounded-b-lg overflow-y-auto p-8 border border-gray-200">
                {Array.from({ length: Math.floor((window.innerHeight - 200) / 40) }).map((_, index) => (
                  <motion.p
                    key={index}
                    className="w-full bg-gray-100 h-6 rounded my-2" 
                    initial={{ width: '0%', opacity: 0 }}
                    animate={{ width: '100%', opacity: 1 }}
                    transition={{
                      width: {
                        duration: 0.8,
                        delay: 1.5 + index * 0.4,
                        ease: "easeInOut"
                      },
                      opacity: {
                        duration: 0.3,
                        delay: 1.5 + index * 0.4
                      }
                    }}
                  ></motion.p>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </form>
  );
};

// Dropdown placeholders
const renderDropdowns = () => {
  return [
    <motion.li key="1" variants={itemVariants} className="col-span-1">
      <p className="w-full bg-gray-100 h-6 rounded"></p>
    </motion.li>,
    <motion.li key="2" variants={itemVariants} className="col-span-1">
      <p className="w-full bg-gray-100 h-6 rounded"></p>
    </motion.li>,
    <motion.li key="3" variants={itemVariants} className="col-span-1">
      <p className="w-full bg-gray-100 h-6 rounded"></p>
    </motion.li>,
    <motion.li key="4" variants={itemVariants} className="col-span-1">
      <p className="w-full bg-gray-100 h-6 rounded"></p>
    </motion.li>,
  ];
};

// Animated input placeholders
const renderInputs = () => {
  const placeholders = [
    { label: "Topic", type: "input", width: "128px" },
    { label: "Keywords", type: "input", width: "156px" },
    { label: "Details", type: "textarea", width: "256px" },
  ];

  return placeholders.map((placeholder, index) => (
    <motion.div
      key={index}
      variants={itemVariants}
    >
      <InputCard key={index} title={placeholder.label} description="" isSkeleton={true} width={placeholder.width} isExpanded={false} onToggle={() => { }} hasContent={false}>
        {placeholder.type === "textarea" ? (
          <p className="w-full bg-gray-100 h-6 rounded"></p>
        ) : (
          <p className="w-full bg-gray-100 h-6 rounded"></p>
        )}
      </InputCard>
    </motion.div>
  ));
};

// Reusable Framer Motion variants for items
const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export default SkeletonTemplatePage;