import { useEffect, useState, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { DocumentManager } from '../../utils/documents/DocumentManager';
import { Check, RefreshCcw, AlertCircle } from 'lucide-react';
import { gemini_model } from '../../config/fbConfig';
import debounce from 'lodash/debounce';
import MarkdownIt from 'markdown-it';
import { AIServiceProvider } from '../../services/AIServiceContext'
import { ContextButton } from './ContextButton';
import { usePostHog } from 'posthog-js/react';
import { AIService } from '~/services/AIService';
import { ContinueWritingButton } from './ContinueWritingButton';

interface DocumentEditorProps {
  documentId?: string;
  userId: string;
  initialContent?: string;
  initialTitle?: string;
  templateId?: string;
  onSaved?: (documentId: string) => void;
  onInit?: () => void;
  ref?: React.ForwardedRef<DocumentEditorRef>;
}

// Define the public methods that can be called via ref
export interface DocumentEditorRef {
  insertText: (text: string, insertionPoint?: Node) => void;
  finalizeMarkdown: () => void;
  generateTitleFromContent: () => Promise<void>;
  cleanupPreviousContent: () => void;
  initializeDocument: () => Promise<string>;
}


export const DocumentEditor = forwardRef<DocumentEditorRef, DocumentEditorProps>(({ 
  documentId, 
  userId,
  initialContent = '',
  initialTitle = 'Unbenanntes Dokument',
  templateId = null,
  onSaved,
  onInit
}, ref) => {
  const [editorInstance, setEditorInstance] = useState<TinyMCEEditor | null>(null);
  const [content, setContent] = useState(initialContent);
  const [title, setTitle] = useState('Unbenanntes Dokument');
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'unsaved' | 'error'>('saved');
  const documentRef = useRef<DocumentManager | null>(null);
  const [hasInitialDocument, setHasInitialDocument] = useState(false);
  const [lastSavedContent, setLastSavedContent] = useState<string>(initialContent);
  const [lastSavedTitle, setLastSavedTitle] = useState<string>(initialTitle);
  const [showSavedStatus, setShowSavedStatus] = useState(true);
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const posthog = usePostHog();

  // Initialize AIService with proper ref handling
  const aiServiceRef = useRef<AIService | null>(null);

  // Initialize AIService when ref is available
  useEffect(() => {
    if (!aiServiceRef.current) {
      aiServiceRef.current = new AIService({
        editorRef: ref as React.RefObject<DocumentEditorRef>,
        userId,
        posthog
      });
    }
  }, [ref, userId, posthog]);

  // Expose a cleanup function for AIService
  const cleanupPreviousContent = useCallback(async () => {
    if (aiServiceRef.current) {
      aiServiceRef.current.cleanupContent();
    }
    previousContent.current = null;
  }, []);
  // Initialize markdown-it parser with enhanced configuration
  const mdParser = useRef(new MarkdownIt({
    html: true,
    breaks: false, // Disable automatic breaks
    typographer: true,
  })
  .enable(['heading']) // Ensure headers are parsed correctly
  );
  
  // Add a ref to store the complete raw response
  const completeResponse = useRef('');

  // Store the previous content
  const previousContent = useRef<string | null>(null);

  // Add function to finalize markdown conversion
  const finalizeMarkdown = useCallback(async () => {
    if (!editorInstance || !completeResponse.current) return;

    try {
      // Convert markdown to HTML
      const finalHtml = mdParser.current.render(completeResponse.current);

      // Restore previous content, add newlines and HTML
      editorInstance.setContent(
        previousContent.current + 
        '\n\n' + 
        finalHtml
      );

      // Move cursor to end
      editorInstance.selection.select(editorInstance.getBody(), true);
      editorInstance.selection.collapse(false);

      // Reset for next generation
      completeResponse.current = '';
      previousContent.current = null;
    } catch (error) {
      console.error('Error converting markdown to HTML:', error);
    }
  }, [editorInstance]);

  useImperativeHandle(ref, () => ({
    insertText: async (text: string) => {
      if (text === undefined || text === null || text === 'undefined' || isProcessing) {
        return;
      }

      if (editorInstance) {
        setIsProcessing(true);
        try {
          // Store initial content before first insert
          if (previousContent.current === null) {
            previousContent.current = editorInstance.getContent();
            // Add two newlines after the previous content
            editorInstance.setContent(previousContent.current + '\n\n');
          }

          // Count newlines before adding new text
          const previousNewlines = (completeResponse.current.match(/\n/g) || []).length;
          
          // Append to complete response for final conversion
          completeResponse.current += text;
          
          // Count newlines after adding new text
          const currentNewlines = (completeResponse.current.match(/\n/g) || []).length;
          
          // If number of newlines increased, finalize markdown
          if (currentNewlines > previousNewlines) {
            await finalizeMarkdown();
            return;
          }

          // Move cursor to end of document before inserting
          editorInstance.selection.select(editorInstance.getBody(), true);
          editorInstance.selection.collapse(false);

          // Insert the raw text directly
          editorInstance.selection.setContent(text);

          // Scroll to bottom
          const container = editorInstance.getContainer();
          if (container) {
            container.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        } finally {
          setIsProcessing(false);
        }
      }
    },
    finalizeMarkdown,
    generateTitleFromContent: async () => {
      if (editorInstance && title === 'Unbenanntes Dokument') {
        const currentContent = editorInstance.getContent();
        await generateTitle(currentContent);
      }
    },
    cleanupPreviousContent,
    initializeDocument
  }));

  // Initialize or load document
  useEffect(() => {
    const initDocument = async () => {
      if (documentId) {
        // Load existing document
        const doc = await DocumentManager.loadById(userId, documentId);
        if (doc) {
          documentRef.current = doc;
          setContent(doc.getRichText());
          setTitle(doc.getTitle());
          setHasInitialDocument(true);
        }
      } else if (initialContent) {
        // Only create if we haven't created an initial document yet
        if (!hasInitialDocument) {
          const doc = await DocumentManager.create(userId, {
            title: 'Unbenanntes Dokument',
            richText: initialContent,
            originTemplate: templateId,
            status: 'Entwurf'
          });
          documentRef.current = doc;
          setHasInitialDocument(true);
          onSaved?.(doc.getId());
        }
      }
    };

    initDocument();
  }, [documentId, userId, initialContent, templateId, onSaved, hasInitialDocument]);

  // Add function to verify content matches
  const verifyContentMatch = useCallback(() => {
    const currentContent = editorInstance?.getContent() || '';
    const currentTitle = title;
    
    return currentContent === lastSavedContent && currentTitle === lastSavedTitle;
  }, [editorInstance, title, lastSavedContent, lastSavedTitle]);

  // Add a new ref to track initial save status
  const isInitialSaveInProgress = useRef(false);

  // Add new function to handle document initialization
  const initializeDocument = useCallback(async () => {
    // If we already have a document, return its ID
    if (documentRef.current) {
      return documentRef.current.getId();
    }

    // If initial save is in progress, wait for it
    if (isInitialSaveInProgress.current) {
      return new Promise<string>((resolve) => {
        const checkDocument = setInterval(() => {
          if (documentRef.current) {
            clearInterval(checkDocument);
            resolve(documentRef.current.getId());
          }
        }, 100);
      });
    }

    try {
      isInitialSaveInProgress.current = true;
      
      const doc = await DocumentManager.create(userId, {
        title: title,
        richText: editorInstance?.getContent() || '',
        originTemplate: templateId,
        status: 'Entwurf'
      });
      
      documentRef.current = doc;
      setHasInitialDocument(true);
      
      const documentId = doc.getId();
      onSaved?.(documentId);
      
      return documentId;
    } finally {
      isInitialSaveInProgress.current = false;
    }
  }, [userId, title, templateId, editorInstance, onSaved]);

  // Update saveDocument to use initializeDocument
  const saveDocument = useCallback(async (updatedTitle?: string) => {
    if (!editorInstance) return;

    const currentContent = editorInstance.getContent();
    const currentTitle = updatedTitle || title;
    
    // Don't save empty documents
    if (currentTitle === 'Unbenanntes Dokument' && (!currentContent || currentContent.trim() === '')) {
      setSaveStatus('saved');
      setShowSavedStatus(true);
      return;
    }

    setSaveStatus('saving');
    setShowSavedStatus(false);

    try {
      // If no document exists yet, initialize it
      if (!documentRef.current) {
        await initializeDocument();
      }
      
      // Now we can safely save
      if (documentRef.current) {
        await documentRef.current.save(currentTitle, currentContent, { status: 'Entwurf' });
      }

      setLastSavedContent(currentContent);
      setLastSavedTitle(currentTitle);
      setSaveStatus('saved');
      
      setTimeout(() => {
        setShowSavedStatus(true);
      }, 2000);
    } catch (error) {
      console.error('Error saving document:', error);
      setSaveStatus('error');
      setShowSavedStatus(true);
    }
  }, [editorInstance, title, initializeDocument]);

  // Update the debouncedSave function
  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(async (newTitle?: string, newContent?: string) => {
      // Don't proceed if initial save is in progress
      if (isInitialSaveInProgress.current) {
        return;
      }
      
      if (!documentRef.current) return;
      
      try {
        setSaveStatus('saving');
        setShowSavedStatus(false);
        
        await documentRef.current.save(newTitle, newContent, { status: documentRef.current.getStatus() });
        
        // Update last saved content/title after successful save
        if (newContent) setLastSavedContent(newContent);
        if (newTitle) setLastSavedTitle(newTitle);
        
        setSaveStatus('saved');
        setTimeout(() => setShowSavedStatus(true), 2000);
      } catch (error) {
        console.error('Error saving document:', error);
        setSaveStatus('error');
        setShowSavedStatus(true);
      }
    }, 1000),
    []
  );

  // Update content change handler
  const handleEditorChange = useCallback((newContent: string) => {
    setContent(newContent);
    
    if (showSavedStatus) {
      setShowSavedStatus(false);
    }
    
    setSaveStatus('unsaved');
    debouncedSave(undefined, newContent);
  }, [debouncedSave, showSavedStatus]);

  // Update title change handler
  const handleTitleChange = useCallback((newTitle: string) => {
    setTitle(newTitle);
    
    if (showSavedStatus) {
      setShowSavedStatus(false);
    }
    
    setSaveStatus('unsaved');
    debouncedSave(newTitle, undefined);
  }, [debouncedSave, showSavedStatus]);

  // Add periodic verification
  useEffect(() => {
    const verificationInterval = setInterval(() => {
      if (!verifyContentMatch()) {
        setSaveStatus('unsaved');
        saveDocument();
      }
    }, 5000); // Check every 5 seconds

    return () => clearInterval(verificationInterval);
  }, [verifyContentMatch, saveDocument]);

  // Add styles for disabled state
  const editorStyles = `
    body { 
      font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
      font-size: 16px;
      line-height: 1.6;
    }
    body.mce-content-readonly {
      background-color: #f9fafb !important;
      cursor: not-allowed !important;
    }
    .tox-tinymce {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  `;

  // Add this function inside the DocumentEditor component
  const generateTitle = async (content: string) => {
    if (!content || content.trim() === '') return;
    
    try {
      const prompt = [
        { text: "Generiere einen kurzen, prägnanten Dokumenten Namen (maximal 5 Wörter) für den folgenden Text. Antworte nur mit dem Titel, ohne weitere Erklärungen oder Formatierungen:" },
        { text: content }
      ];

      const result = await gemini_model.generateContent(prompt);
      const generatedTitle = result.response.text().trim();
      
      if (generatedTitle) {
        setTitle(generatedTitle);
        debouncedSave(generatedTitle, undefined);
      }
    } catch (error) {
      console.error('Error generating title:', error);
    }
  };

  // Clean up the timeout on unmount
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        // eslint-disable-next-line
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  // Add cleanup for debounced save
  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  return (
    <AIServiceProvider userId={userId} editorRef={ref as React.RefObject<DocumentEditorRef>}>
      <div className="flex flex-col h-full relative">
        <div className="flex items-center justify-between px-2 h-12 border-t border-x rounded-t-lg border-gray-200 bg-white relative">
          <input
            type="text"
            value={title}
            onChange={(e) => handleTitleChange(e.target.value)}
            className="text-xl font-medium border-none focus:outline-none flex-grow mr-4"
            placeholder="Unbenanntes Dokument"
          />
          <div className="flex items-center text-gray-500">
            {!showSavedStatus && (
              <RefreshCcw className="w-5 h-5 animate-spin" />
            )}
            {showSavedStatus && saveStatus !== 'error' && (
              <Check className="w-5 h-5 text-green-500" />
            )}
            {saveStatus === 'error' && (
              <AlertCircle className="w-5 h-5 text-red-500" />
            )}
          </div>
        </div>

        <div className="flex-grow relative -mt-[2px]">
          <div className="relative h-full">
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              value={content}
              onEditorChange={handleEditorChange}
              onInit={(_, editor) => {
                setEditorInstance(editor);
                onInit?.();
              }}
              init={{
                height: '100%',
                menubar: false,
                contextmenu: 'copy paste cut link', // Add the desired options here
                elementpath: false,
                language: 'de',
                language_url: '/tinymce/de.js',
                branding: false,
                licenseKey: 'gpl',
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'preview',
                  'help',
                  'wordcount',
                ],
                toolbar: 'undo redo | blocks | bold italic customcopy | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
                toolbar_mode: 'wrap',
                toolbar_sticky: true,
                
                // Update mobile configuration
                mobile: {
                  menubar: false,
                  toolbar_mode: 'scrolling',
                  toolbar_sticky: false,
                  toolbar_location: 'top',
                  toolbar: 'bold italic customcopy',
                  plugins: [], // Disable plugins on mobile
                  statusbar: false, // Hide statusbar on mobile
                },
                
                content_style: editorStyles,
                setup: (editor) => {
                  editor.on('init', () => {
                    editor.getDoc().body.style.backgroundColor = '#ffffff';
                    editor.getDoc().body.style.color = '#333333';
                  });

                  // Add custom word and character count display
                  editor.on('init', () => {
                    // Add Custom Word Counter
                    const statusbar = editor.getContainer().querySelector('.tox-statusbar__text-container');
                    if (statusbar) {
                      const customStatus = document.createElement('span');
                      customStatus.className = 'tox-statusbar__wordcount';
                      statusbar.insertBefore(customStatus, statusbar.firstChild);

                      const updateWordCount = () => {
                        const wordCount = editor.plugins.wordcount.getCount();
                        const charCount = editor.plugins.wordcount.body.getCharacterCount();
                        customStatus.innerHTML = `Wörter: ${wordCount} | Zeichen: ${charCount}`;
                      };

                      editor.on('WordCountUpdate', updateWordCount);
                      updateWordCount(); // Initial update

                      // Hide the default word count display
                      const defaultWordCount = editor.getContainer()?.querySelector('.tox-statusbar__right-container');
                      if (defaultWordCount instanceof HTMLElement) {
                        defaultWordCount.style.display = 'none';
                      }

                      // Set justify-content to flex-start for the statusbar text container
                      if (statusbar instanceof HTMLElement) {
                        statusbar.style.justifyContent = 'flex-start';
                      }
                    }

                    // Remove all top borders and styling with !important
                    const editorContainer = editor.getContainer();
                    if (editorContainer instanceof HTMLElement) {
                      editorContainer.style.setProperty('border-top', 'none', 'important');
                      editorContainer.style.setProperty('border-top-left-radius', '0', 'important');
                      editorContainer.style.setProperty('border-top-right-radius', '0', 'important');
                      
                      // Remove toolbar borders
                      const toolbar = editorContainer.querySelector('.tox-toolbar-overlord');
                      if (toolbar instanceof HTMLElement) {
                        toolbar.style.setProperty('border-top', 'none', 'important');
                      }
                      
                      const toolbarPrimary = editorContainer.querySelector('.tox-toolbar__primary');
                      if (toolbarPrimary instanceof HTMLElement) {
                        toolbarPrimary.style.setProperty('border-top', 'none', 'important');
                      }
                      
                      const editorHeader = editorContainer.querySelector('.tox-editor-header');
                      if (editorHeader instanceof HTMLElement) {
                        editorHeader.style.setProperty('border-top', 'none', 'important');
                      }

                      // Additional elements that might have borders
                      const menubar = editorContainer.querySelector('.tox-menubar');
                      if (menubar instanceof HTMLElement) {
                        menubar.style.setProperty('border-top', 'none', 'important');
                      }

                      const editArea = editorContainer.querySelector('.tox-edit-area');
                      if (editArea instanceof HTMLElement) {
                        editArea.style.setProperty('border-top', 'none', 'important');
                      }

                      const iframe = editorContainer.querySelector('.tox-edit-area__iframe');
                      if (iframe instanceof HTMLElement) {
                        iframe.style.setProperty('border-top', 'none', 'important');
                      }
                    }
                  });

                  // Add visual indicator for generating state
                  editor.on('init', () => {
                    const editorContainer = editor.getContainer();
                    if (editorContainer instanceof HTMLElement) {
                      editorContainer.style.opacity = '1';
                    }
                  });

                  // Add copy event tracking
                  editor.on('init', () => {
                    editor.getDoc().addEventListener('copy', () => {
                      // Track copy event with posthog if not already fired
                      if (!sessionStorage.getItem('copyEventFired') || sessionStorage.getItem('copyEventFired') === 'false') {
                        // @ts-ignore (posthog might not be typed)
                        window.posthog?.capture('document_editor:copy_text', {
                          document_id: documentRef.current?.getId(),
                          document_title: title
                        });
                        sessionStorage.setItem('copyEventFired', 'true');
                      }
                    });

                    // Add context menu (right-click) tracking
                    editor.getDoc().addEventListener('contextmenu', () => {
                      if (!sessionStorage.getItem('copyEventFired') || sessionStorage.getItem('copyEventFired') === 'false') {
                        // @ts-ignore (posthog might not be typed)
                        window.posthog?.capture('document_editor:copy_text', {
                          document_id: documentRef.current?.getId(),
                          document_title: title
                        });
                        sessionStorage.setItem('copyEventFired', 'true');
                      }
                    });
                  });

                  // Add custom copy button
                  editor.ui.registry.addButton('customcopy', {
                    icon: 'copy',
                    tooltip: 'Text kopieren',
                    onAction: () => {
                      const htmlContent = editor.getContent();
                      const tempDiv = document.createElement('div');
                      tempDiv.innerHTML = htmlContent;
                      const plainText = tempDiv.textContent || tempDiv.innerText;

                      // Create a clipboard data object with multiple formats
                      const clipboardData = new ClipboardItem({
                        'text/plain': new Blob([plainText], { type: 'text/plain' }),
                        'text/html': new Blob([htmlContent], { type: 'text/html' })
                      });

                      // Write to clipboard and show feedback
                      navigator.clipboard.write([clipboardData])
                        .then(() => {
                          // Change button icon temporarily to checkmark
                          const button = editor.container.querySelector('button[aria-label="Text kopieren"]');
                          if (button) {
                            const icon = button.querySelector('.tox-icon');
                            if (icon) {
                              // Add success state
                              icon.innerHTML = '<svg width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path></svg>';
                              button.classList.add('text-green-500');
                              
                              // Create and show notification
                              const notification = document.createElement('div');
                              notification.innerHTML = 'Text erfolgreich kopiert';
                              notification.style.cssText = `
                                position: fixed;
                                bottom: 20px;
                                left: 50%;
                                transform: translateX(-50%);
                                background: #10B981;
                                color: white;
                                padding: 8px 16px;
                                border-radius: 4px;
                                font-size: 14px;
                                z-index: 9999;
                                opacity: 0;
                                transition: opacity 0.2s ease-in-out;
                              `;
                              document.body.appendChild(notification);
                              
                              // Fade in
                              setTimeout(() => notification.style.opacity = '1', 0);
                              
                              // Remove notification and reset button after 1.5 seconds
                              setTimeout(() => {
                                notification.style.opacity = '0';
                                setTimeout(() => notification.remove(), 200);
                                icon.innerHTML = '<svg width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg>';
                                button.classList.remove('text-green-500');
                              }, 1500);
                            }
                          }
                        })
                        .catch(console.error);

                      if (!sessionStorage.getItem('copyEventFired') || sessionStorage.getItem('copyEventFired') === 'false') {
                        // @ts-ignore (posthog might not be typed)
                        window.posthog?.capture('document_editor:copy_text', {
                          document_id: documentRef.current?.getId(),
                          document_title: title
                        });
                        sessionStorage.setItem('copyEventFired', 'true');
                      }
                    }
                  });
                }
              }}
            />
            {editorInstance && (
              <div className="absolute inset-0 z-50 pointer-events-none">
                <div className="relative w-full h-full pointer-events-none">
                  <ContextButton editor={editorInstance} />
                  {/* <ContinueWritingButton editor={editorInstance} /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AIServiceProvider>
  );
}); 