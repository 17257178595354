import { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { doc, collection, onSnapshot, firestore, auth, getDoc, getDocs, updateDoc, setDoc } from '../config/fbConfig';
import { UserData } from '../utils/userUtils';
import { getStripeProducts, getUserTier } from '../utils/userUtils';
interface Template {
  id: string;
  title: string;
  type: string;
  favorite?: boolean;
  description?: string;
  placeholders?: Array<{
    label: string;
    type: string;
  }>;
}

interface Persona {
  id: string;
  name: string;
  personatext: string;
  standard?: boolean;
}

interface UserDataContextType {
  userData: UserData | null;
  isLoading: boolean;
  writeData: any | null;
  templates: Template[];
  recentChats: any[];
  sidebarState: boolean;
  userTier: string | null;
  personas: Persona[];
  setSidebarState: (state: boolean) => void;
}

const UserDataContext = createContext<UserDataContextType>({
  userData: null,
  isLoading: true,
  writeData: null,
  templates: [],
  recentChats: [],
  sidebarState: false,
  userTier: null,
  personas: [],
  setSidebarState: () => {}
});

export const UserDataProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [writeData, setWriteData] = useState<any | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [recentChats, setRecentChats] = useState<any[]>([]);
  const [sidebarState, setSidebarState] = useState(false);
  const [userTier, setUserTier] = useState<string | null>(null);
  const [personas, setPersonas] = useState<Persona[]>([]);

  useEffect(() => {
    let unsubscribes: (() => void)[] = [];
    const defaultTemplates: Template[] = require('../data/templates.json');

    // Add auth state listener
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (!user) {
        setTemplates(defaultTemplates);
        setIsLoading(false);
        return;
      }

      const userDocRef = doc(collection(firestore, "users"), user.uid);
      const writeDataRef = doc(collection(firestore, "users", user.uid, "writedata"), "appdata");
      const templatesRef = collection(firestore, "users", user.uid, "templates");
      const personasRef = collection(firestore, "users", user.uid, "personas");

      // User data listener
      unsubscribes.push(onSnapshot(userDocRef, (userDocSnap: any) => {
        if (userDocSnap.exists()) {
          const userDocData = userDocSnap.data();
          setUserData(prevData => ({
            userData: userDocData,
            productData: prevData?.productData || null
          }));
        }
      }));

      // Write data listener
      unsubscribes.push(onSnapshot(writeDataRef, (writeDataSnap: any) => {
        if (writeDataSnap.exists()) {
          const data = writeDataSnap.data();
          setWriteData(data);
        }
      }));

      // Updated Templates listener with better error handling
      unsubscribes.push(onSnapshot(templatesRef, (templatesSnap) => {
        try {
          const userTemplates: Template[] = [];
          templatesSnap.forEach((doc) => {
            const templateData = doc.data();
            if (templateData.template_data) {
              userTemplates.push({
                ...templateData.template_data,
                id: doc.id // Ensure ID is always set
              });
            } else if (templateData.title && templateData.type) {
              userTemplates.push({ 
                id: doc.id, 
                title: templateData.title,
                type: templateData.type,
                favorite: templateData.favorite
              });
            }
          });
          
          // Combine with default templates, ensuring no duplicates by ID
          const combinedTemplates = [
            ...defaultTemplates,
            ...userTemplates.filter(ut => !defaultTemplates.some((dt: Template) => dt.id === ut.id))
          ];
          
          setTemplates(combinedTemplates);
        } catch (error) {
          console.error("Error processing templates:", error);
          setTemplates([...defaultTemplates]); // Fallback to default templates
        }
      }, (error) => {
        console.error("Error in templates listener:", error);
        setTemplates([...defaultTemplates]); // Fallback to default templates
      }));

      // Recent chats listener
      const chatDocRef = doc(collection(firestore, "users", user.uid, "chat"), "recent");
      unsubscribes.push(
        onSnapshot(chatDocRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data()?.chats;
            if (data) {
              setRecentChats(data);
            }
          }
        })
      );

      // Get initial sidebar state
      getDoc(doc(collection(firestore, "users", user.uid, "writedata"), "appdata"))
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            setSidebarState(docSnapshot.data()?.sidebarState || false);
          }
        });

      // Get user tier
      const fetchUserTier = async () => {
        if (!auth.currentUser) return
        const userTier = await getUserTier(auth.currentUser.uid);
        const data = await getStripeProducts();

        let subscriptionName = userTier.firebaseRole;
        for (let key in data) {
          if (JSON.stringify(data[key]) === JSON.stringify(userTier)) {
            subscriptionName = key;
            break;
          }
        }
        setUserTier(subscriptionName);
      };
      fetchUserTier();

      // Personas listener
      unsubscribes.push(onSnapshot(personasRef, (personasSnap) => {
        try {
          const userPersonas: Persona[] = [];
          personasSnap.forEach((doc) => {
            const personaData = doc.data();
            userPersonas.push({
              id: doc.id,
              name: personaData.name || '',
              personatext: personaData.personatext || '',
              standard: personaData.standard || false
            });
          });
          setPersonas(userPersonas);
        } catch (error) {
          console.error("Error processing personas:", error);
          setPersonas([]);
        }
      }, (error) => {
        console.error("Error in personas listener:", error);
        setPersonas([]);
      }));

      // Remove loadInitialTemplates since the listener will handle all updates
      Promise.all([
        getDoc(userDocRef),
        getDoc(writeDataRef)
      ]).finally(() => setIsLoading(false));
    });

    // Add auth unsubscribe to cleanup
    return () => {
      unsubscribeAuth();
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, []);

  const updateSidebarState = async (newState: boolean) => {
    if (!auth.currentUser?.uid) return;
    
    const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser.uid, "writedata"), "appdata");
    const docSnapshot = await getDoc(writeDataDocRef);
    
    if (docSnapshot.exists()) {
      await updateDoc(writeDataDocRef, { sidebarState: newState });
    } else {
      await setDoc(writeDataDocRef, { sidebarState: newState });
    }
    setSidebarState(newState);
  };

  return (
    <UserDataContext.Provider value={{ 
      userData, 
      writeData, 
      templates, 
      isLoading,
      recentChats,
      sidebarState,
      userTier,
      personas,
      setSidebarState: updateSidebarState
    }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext); 