import React, { useRef, useCallback, useEffect, useState } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Input } from "../../components/ui/input";

interface EnhancedTextareaProps {
    value: string;
    onChange: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    placeholder?: string;
    disabled?: boolean;
    maxRows?: number;
}

export function EnhancedTextarea({
    value,
    onChange,
    onKeyDown,
    placeholder,
    disabled,
}: EnhancedTextareaProps) {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedPlaceholder, setSelectedPlaceholder] = useState<{
        text: string;
        startIndex: number;
        endIndex: number;
        rect: DOMRect;
    } | null>(null);

    const getHighlightedHtml = useCallback((text: string) => {
        if (!text) return '';
        
        const escaped = text
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\n/g, '<br>');
        
        return escaped.replace(
            /\[(.*?)\]/g,
            '<span class="placeholder-span bg-purple-100 text-purple-800 mx-[1px] rounded cursor-pointer hover:bg-purple-200" data-placeholder="true">[$1]</span>'
        );
    }, []);

    const updateOverlay = useCallback(() => {
        if (!overlayRef.current || !textareaRef.current) return;
        
        const html = getHighlightedHtml(textareaRef.current.value);
        overlayRef.current.innerHTML = html;
        overlayRef.current.scrollTop = textareaRef.current.scrollTop;
    }, [getHighlightedHtml]);

    const handleInput = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        onChange(newValue);
    }, [onChange]);

    const handleClick = useCallback((e: React.MouseEvent) => {
        const target = e.target as HTMLElement;
        const placeholderElement = target.closest('[data-placeholder="true"]');
        
        if (placeholderElement) {
            e.preventDefault();
            e.stopPropagation();
            
            const text = placeholderElement.textContent || '';
            const rect = placeholderElement.getBoundingClientRect();
            
            const fullText = textareaRef.current?.value || '';
            const startIndex = fullText.indexOf(text);
            const endIndex = startIndex + text.length;
            
            setSelectedPlaceholder({
                text,
                startIndex,
                endIndex,
                rect
            });
            setInputValue(text.replace(/^\[(.*)\]$/, '$1'));
            setPopoverOpen(true);
        } else if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, []);

    const handleReplacePlaceholder = useCallback((newText: string) => {
        if (!selectedPlaceholder || !textareaRef.current) return;

        const { startIndex, endIndex } = selectedPlaceholder;
        const fullText = textareaRef.current.value;
        const newFullText = fullText.slice(0, startIndex) + newText + fullText.slice(endIndex);
        
        onChange(newFullText);
        setSelectedPlaceholder(null);
        setInputValue('');
        
        textareaRef.current.focus();
    }, [selectedPlaceholder, onChange]);

    useEffect(() => {
        updateOverlay();
    }, [value, updateOverlay]);

    return (
        <div className="relative" onClick={handleClick}>
            <textarea
                ref={textareaRef}
                value={value}
                onChange={handleInput}
                onKeyDown={onKeyDown}
                onScroll={updateOverlay}
                disabled={disabled}
                placeholder={placeholder}
                className={`
                    w-full min-h-[40px] p-4 
                    whitespace-pre-wrap break-words 
                    outline-none focus:outline-none focus-visible:outline-none focus:ring-0
                    bg-transparent
                    resize-none
                    absolute inset-0
                    text-transparent
                    caret-black
                    border-none
                    scrollbar-none
                    placeholder:text-muted-foreground
                `}
                style={{
                    WebkitTextFillColor: 'transparent',
                }}
            />
            <div
                ref={overlayRef}
                className={`
                    w-full min-h-[40px] p-4 
                    whitespace-pre-wrap break-words 
                    font-sans text-base
                    ${!value && placeholder ? 'before:content-[attr(data-placeholder)] before:text-muted-foreground' : ''}
                    scrollbar-none
                `}
                data-placeholder={placeholder}
            />
            {selectedPlaceholder && (
                <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                    <PopoverTrigger asChild>
                        <div
                            style={{
                                position: 'absolute',
                                top: selectedPlaceholder.rect.top - textareaRef.current?.getBoundingClientRect().top!,
                                left: selectedPlaceholder.rect.left - textareaRef.current?.getBoundingClientRect().left!,
                                width: selectedPlaceholder.rect.width,
                                height: selectedPlaceholder.rect.height,
                                zIndex: 30,
                            }}
                            className="cursor-pointer"
                        />
                    </PopoverTrigger>
                    <PopoverContent 
                        className="w-72 p-4 bg-white" 
                        side="top"
                        align="start"
                        sideOffset={5}
                    >
                        <div className="space-y-3">
                            <div className="font-medium text-sm">
                                {selectedPlaceholder.text}
                            </div>
                            <Input
                                autoFocus
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleReplacePlaceholder(inputValue);
                                        setPopoverOpen(false);
                                    }
                                }}
                                className="w-full focus-visible:ring-gray-200"
                            />
                            <div className="text-xs text-muted-foreground">
                                Mit Enter bestätigen
                            </div>
                        </div>
                    </PopoverContent>
                </Popover>
            )}
        </div>
    );
} 