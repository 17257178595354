import React from 'react';
import { User } from "lucide-react";
import { auth } from "../../config/fbConfig";
import { useEffect, useState } from "react";
import { getAllUserData } from "~/utils/userUtils";

interface ProfileIconProps {
  src?: string;
  className?: string;
  showLetter?: boolean;
  letter?: string;
}

export function ProfileIcon({ 
  src, 
  className = "w-8 h-8",
  showLetter = false,
  letter
}: ProfileIconProps) {
  const [firstLetter, setFirstLetter] = useState<string>(letter || "");

  useEffect(() => {
    const loadUserData = async () => {
      if (!letter && auth.currentUser) {
        const userData = await getAllUserData(auth.currentUser.uid);
        const fullName = userData?.userData?.fullname || 
                        `${userData?.userData?.firstname || ''} ${userData?.userData?.lastname || ''}`;
        setFirstLetter(fullName.charAt(0).toUpperCase());
      }
    };

    loadUserData();
  }, [letter]);

  if (!src && (showLetter || letter) && firstLetter) {
    return (
      <div className={`flex items-center justify-center rounded-full bg-secondary text-white font-medium ${className}`}>
        {firstLetter}
      </div>
    );
  }

  if (!src) {
    return (
      <div className={`flex items-center justify-center rounded-full bg-gray-100 ${className}`}>
        <User className="w-5 h-5 text-gray-500" />
      </div>
    );
  }

  return (
    <img src={src}
      alt="Profile"
      className={`rounded-full object-contain ${className}`}
    />
  );
}

export default ProfileIcon; 