import React, { useState } from 'react';
import { Copy, Check, User } from 'lucide-react';
import { ProfileIcon } from '../profile/ProfileIcon';
import ReactMarkdown, { Components } from 'react-markdown';
import { Badge } from '../ui/badge';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

interface MessageProps {
  content: string;
  isAI: boolean;
  isBlurred?: boolean;
  onCopy: (content: string) => void;
  firstLetter: string;
  persona?: string;
}

export const Message = ({ content, isAI, isBlurred, onCopy, firstLetter, persona }: MessageProps) => {
  const [isCopied, setIsCopied] = useState(false);

  // Filter out persona instructions from the content
  const displayContent = content.split('\n').filter(line => !line.startsWith('---Nutze den Tonfall')).join('\n');

  // Custom components for ReactMarkdown
  const components: Components = {
    a: ({ node, ...props }) => (
      <a 
        {...props} 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-gray-800 hover:text-purple-900 hover:underline transition-colors"
      />
    ),
    ol: ({ node, ...props }) => (
      <ol {...props} className="my-2" />
    ),
    ul: ({ node, ...props }) => (
      <ul {...props} className="my-2" />
    ),
    li: ({ node, ...props }) => (
      <li {...props} className="my-1" />
    ),
    
    h1: ({ node, ...props }) => (
      <h1 {...props} className="text-2xl font-bold my-3" />
    ),
    h2: ({ node, ...props }) => (
      <h2 {...props} className="text-xl font-bold my-3" />
    ),
    h3: ({ node, ...props }) => (
      <h3 {...props} className="text-lg font-bold my-2" />
    ),
    h4: ({ node, ...props }) => (
      <h4 {...props} className="text-base font-bold my-2" />
    ),
    h5: ({ node, ...props }) => (
      <h5 {...props} className="text-sm font-bold my-2" />
    ),
    
    p: ({ node, ...props }) => (
      <p {...props} className="my-2 leading-relaxed" />
    ),
  };

  return (
    <div className={`p-4 rounded-xl border border-gray-100 ${isAI ? 'bg-gray-50' : 'bg-white'}`}>
      <div className="flex gap-4">
        {isAI ? (
          <ProfileIcon 
            src="/assets/images/logo-0.svg"
            className="w-8 h-8 flex-shrink-0 object-contain" 
          />
        ) : (
          <ProfileIcon 
            showLetter={true}
            letter={firstLetter}
            className="w-8 h-8 flex-shrink-0"
          />
        )}
        <div className="flex-grow">
          <div className={`prose max-w-none ${isBlurred ? 'blur-sm' : ''}`}>
            <ReactMarkdown 
              className="flex-grow"
              components={components}
              children={displayContent}
            />              
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="pl-0">
              {isAI && persona && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Badge 
                        variant="secondary" 
                        className="text-xs flex items-center gap-1.5"
                      >
                        <User className="h-3 w-3" />
                        {persona}
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent className="bg-white">
                      <p>Aktueller Tonfall</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            {!isBlurred && (
              <button
                onClick={() => {
                  onCopy(displayContent);
                  setIsCopied(true);
                  setTimeout(() => setIsCopied(false), 2000);
                }}
                className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1"
              >
                {isAI && (
                  isCopied ? (
                    <>
                      <Check className="w-4 h-4" />
                      Kopiert
                    </>
                  ) : (
                    <>
                      <Copy className="w-4 h-4" />
                      Kopieren
                    </>
                  )
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}; 