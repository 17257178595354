import { Link, NavLink } from "react-router-dom";
import { auth } from "../config/fbConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LogoutIcon, SettingsIcon, ChatIcon, HomeIcon, PersonaIconSidebar, RewardButton, DocumentIcon } from "../assets/Icons";
import { useEffect, useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useManageStripeSubscription } from "../utils/ManageStripeSubscription";
import { UserData } from "../utils/userUtils";
import { logoutUser } from "../utils/loginUtils";
import { useNavigate } from "react-router-dom";
import MiniSpinningLoader from "../components/loader/mini_spinning_loader";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useTimeLeftUntilMidnight } from "~/utils/useTimeLeftUntilMidnight";
import { ProfileIcon } from "../components/profile/ProfileIcon";
import { useUserData } from "../providers/UserDataProvider";

const links = [
    { path: "/home", label: "Home", icon: HomeIcon },
    { path: "/templates", label: "Vorlagen", icon: DocumentIcon },
    { path: "/tonfall", label: "Tonfall", icon: PersonaIconSidebar },
    { path: "/chat", label: "Chat", icon: ChatIcon },
];



interface Props {
    userData: UserData;
}

const Sidebar = ({ userData }: Props) => {
    // State Variables
    const manageStripeSubscription = useManageStripeSubscription();
    const [showUpgradeButton] = useState(userData.userData?.visuals?.UpgradeButton === undefined ? true : userData.userData?.visuals?.UpgradeButton);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // Providers
    const navigate = useNavigate();
    const links2 = [
        ...(!userData.userData.unlimitedwords ? [{ path: "/reward-center", label: "Zusätzliche Texte", icon: RewardButton }] : []),
        { path: "/settings", label: "Einstellungen", icon: SettingsIcon }
    ];
    const { timeLeftColonFormat } = useTimeLeftUntilMidnight();
    const { recentChats, sidebarState, setSidebarState, userTier } = useUserData();

    // Functions & Event Handlers

    const Logout = () => {
        // Use LogoutUser from loginUtils.ts
        logoutUser(auth, navigate);
    };

    const toggleMenu = async () => {
        setSidebarState(!sidebarState);
    };

    const fullName = userData?.userData?.fullname || userData?.userData?.firstname + ' ' + userData?.userData?.lastname;
    const firstLetter = fullName.charAt(0).toUpperCase() || '';
    const toggleCollapse = () => setIsOpen(!isOpen);
    const currentCreations = userData?.productData?.unlimited ? 100 : (userData?.userData?.creations || 0) + (userData?.userData?.bonus_creations || 0);
    const maxCreations = userData?.productData?.creations;
    const displayedCreations = maxCreations > currentCreations ? maxCreations : currentCreations;

    /* UseEffect Hooks */

    useEffect(() => {
        if (window.matchMedia('(max-width: 768px)').matches) {
            setSidebarState(false);
        }
        document.body.classList.toggle('showMenu', sidebarState);
        return () => {
            document.body.classList.remove('showMenu');
        };
    }, [sidebarState]);

    // Components

    function HistoryComponent() {
        return (
            <div className="w-full">
                {recentChats.length > 0 ? (
                    <ul className="divide-y divide-gray-200 py-2 custom-scrollbar">
                        {recentChats.map((chat) => (
                            <li key={chat.thread_id}>
                                <div
                                    onClick={() => window.location.assign(`/chat?sessionToken=${chat.thread_id}`)}
                                    className="text-sm overflow-auto break-words pl-2 cursor-pointer w-full h-full py-1 hover:bg-gray-50"
                                >
                                    {chat.summary.replace(/^"|"$/g, '')}
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-sm text-gray-500 pl-2 py-2">Keine kürzlichen Chats</p>
                )}
            </div>
        );
    }

    // Add data validation check
    const isDataComplete = userData && 
        userData.userData && 
        userData.productData && 
        typeof userData.productData.unlimited !== 'undefined';

    if (!isDataComplete) {
        return (
            <aside className="sidebar md:w-[211px] py-8 px-4 hidden md:block h-full bg-white">
                <div className="fixed h-full md:w-[176px] top-10 flex flex-col justify-start items-start pb-14">
                <div className="logo flex justify-start w-full gap-4 max-w-[162px]">
                        <button onClick={toggleMenu}>
                            <svg width="16" height="16" viewBox="0 0 23 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.5625 0.1875V3.3125H22.4375V0.1875H0.5625ZM0.5625 6.4375V9.5625H22.4375V6.4375H0.5625ZM0.5625 12.6875V15.8125H22.4375V12.6875H0.5625Z"
                                    fill="#202356" />
                            </svg>
                        </button>
                        <Link to="/home" className="block w-full" data-testid="logo-link">
                            <img
                                className="block h-full w-full object-contain"
                                src="/assets/images/logo.svg"
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="mt-16 w-full space-y-4">
                        {/* Skeleton loading for nav items */}
                        {[1, 2, 3, 4].map((i) => (
                            <div key={i} className="h-10 bg-gray-100 rounded animate-pulse" />
                        ))}
                    </div>
                    <div className="mt-auto w-full">
                        {/* Skeleton loading for user info */}
                        <div className="h-20 bg-gray-100 rounded animate-pulse mb-4" />
                        <div className="h-10 bg-gray-100 rounded animate-pulse" />
                    </div>
                </div>
            </aside>
        );
    }

    return (
        <>
            {/* Mobile View */}
            <div
                className="flex flex-col justify-between mobile-sidebar custom-scrollbar mb-10 md:hidden fixed top-0 w-full max-w-[211px] h-screen left-[-211px] bg-white z-[100] py-[30px] transition-all duration-200 ease-in-out shadow-C2 overflow-y-auto">
                <ul className="flex flex-col items-start w-full px-3 space-y-4 justify-between">
                    <li>
                        <div className="logo" data-testid="logo-link">
                            <Link to="/home" className="block h-auto max-w-xs px-7 mx-auto">
                                <img
                                    className="block h-full w-full object-contain"
                                    src="/assets/images/logo.svg"
                                    alt="logo"
                                />
                            </Link>
                        </div>
                    </li>
                    {(!userData.productData.unlimited && showUpgradeButton) &&
                        <li className="w-full">
                            <div className="block justify-center w-full">
                                <button
                                    className="btn-default w-full relative h-10 text-center nowrap justify-between items-center text-sm flex rounded font-semibold text-secondary bg-transparent tracking-wider border-[1px] border-secondary transition-all duration-200 ease-in-out hover:bg-secondary hover:text-white"
                                    onClick={() => {
                                        manageStripeSubscription();
                                        setLoading(true);
                                    }}>
                                    {loading ?
                                        (<div className="ml-8 items-center h-6 w-6">
                                            <MiniSpinningLoader />
                                        </div>
                                        ) : (
                                            <span
                                                className=" ml-8 transition-colors text-center w-full nowrap duration-200" data-testid="upgrade-button">Upgrade</span>
                                        )}
                                    <FontAwesomeIcon icon={faArrowRight}
                                        className="pl-2 group-hover:text-white w-full transition-colors duration-200" />
                                </button>
                            </div>
                        </li>
                    }
                    {links.map((link) => (
                        <li key={link.path} className="w-full">
                            <NavLink title={link.label} to={link.path}
                                className="nav-link text-[#AAA8BD] text-xs !w-full flex items-center py-2 px-4 rounded-lg !justify-start gap-4">
                                <span className="block w-4 h-4">
                                    <link.icon />
                                </span>
                                <span>{link.label}</span>
                            </NavLink>
                            {link.path === '/chat' && window.location.pathname.startsWith('/chat') && (
                                    <>
                                        <button 
                                            onClick={() => window.location.assign('/chat')}
                                            className="divide-y mt-4 divide-gray-200 py-2 text-purple-700 text-sm overflow-auto break-words pl-2 cursor-pointer"
                                        >
                                            <span>Neuer Chat</span>
                                        </button>
                                        <hr className="border-t w-full" />
                                    </>
                                )}
                        </li>
                    ))}

                </ul>

                <div className="logout mt-auto fixed bottom-0">
                    <div className="w-full h-full flex flex-col items-start px-3 md:px-0">
                        <div className="flex justify-between items-center w-full px-1">
                            <p className="text-[#AAA8BD] text-[14px] font-medium leading-[21px] tracking-[1%]">{userTier}</p>
                            {!userData.productData.unlimited && showUpgradeButton && (
                                <button 
                                    onClick={() => {
                                        manageStripeSubscription();
                                        setLoading(true);
                                    }}
                                    data-testid="upgrade-button2" 
                                    className="rounded-[4px] text-[11px] bg-secondary text-white hover:bg-secondary-600 py-1 px-2"
                                >
                                    {loading ? <span className="flex items-center">Upgrade <div className="h-4 w-4 ml-1"><MiniSpinningLoader /></div></span> : 'Upgrade'}
                                </button>
                            )}
                        </div>
                        <div className="flex flex-col w-full px-1 mb-1 mt-[6px]">
                            <div className="relative w-full h-2 rounded-full bg-[#AAA8BD33]">
                                <div
                                    className="absolute top-0 left-0 h-full bg-secondary rounded-full"
                                    style={{ width: `${(currentCreations / displayedCreations) * 100}%` }}
                                ></div>
                            </div>
                            <div className="flex items-center text-[#AAA8BD] text-xs mt-2">
                                {!userData.userData.freetrial ? (
                                    <span className="text-[10px]">{userData.productData.unlimited ? "Unlimitierte Texte" : `${currentCreations} Texte`}</span>
                                ) : (
                                    <span className="text-[10px]">{currentCreations} Texte (neue Texte in {timeLeftColonFormat})</span>
                                )}
                            </div>
                        </div>
                        {/* User Info Section */}
                        <button onClick={toggleCollapse} className="w-full flex items-center justify-between gap-3 py-4 px-1">
                            <ProfileIcon 
                                showLetter={true}
                                letter={firstLetter}
                                className="w-8 h-8"
                            />
                            <p className="font-semibold text-xs text-gray-600 flex-grow break-words hidden-when-sidebar-collapsed transition-max-height">
                                {fullName}
                            </p>
                            <div className="hidden-when-sidebar-collapsed transition-max-height">
                                {isOpen ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                            </div>
                        </button>

                        {/* Collapsible Links Section */}
                        <div
                            className={`w-full overflow-hidden transition-max-height duration-500 ease-out ${isOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}
                            style={{ transitionProperty: 'max-height, opacity' }}
                        >
                            <ul
                                className={`gap-3 flex flex-col items-center w-full transition-transform duration-500 ease-out `}
                            >
                                {links2.map((link) => (
                                    <li key={link.path} className="w-full" data-testid={`sidebar-link-${link.label.toLowerCase()}`}>
                                        <NavLink
                                            title={link.label}
                                            to={link.path}
                                            className="nav-link text-[#AAA8BD] text-xs w-full flex items-center py-2 px-3 rounded-lg justify-start gap-4"
                                        >
                                            <span className="block w-4 h-4">
                                                <link.icon />
                                            </span>
                                            <span className="text">{link.label}</span>
                                        </NavLink>
                                    </li>
                                ))}

                                {/* Sign Out Button */}
                                <li className="w-full">
                                    <button
                                        data-testid="logout-button" 
                                        title="Sign out"
                                        onClick={Logout}
                                        className="nav-link text-[#AAA8BD] text-xs w-full flex items-center py-2 px-4 rounded-lg justify-start gap-4"
                                    >
                                        <span className="block w-4 h-4">
                                            <LogoutIcon />
                                        </span>
                                        <span className="text">Ausloggen</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Desktop View */}
            <aside className="sidebar md:w-[211px] py-8 px-4 hidden md:block h-full bg-white">
                <div className="fixed h-full md:w-[176px] top-10 flex flex-col justify-start items-start pb-14 overflow-y-auto custom-scrollbar">
                    <div className="logo flex justify-start w-full gap-4 max-w-[162px]">
                        <button onClick={toggleMenu}>
                            <svg width="16" height="16" viewBox="0 0 23 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.5625 0.1875V3.3125H22.4375V0.1875H0.5625ZM0.5625 6.4375V9.5625H22.4375V6.4375H0.5625ZM0.5625 12.6875V15.8125H22.4375V12.6875H0.5625Z"
                                    fill="#202356" />
                            </svg>
                        </button>
                        <Link to="/home" className="block w-full" data-testid="logo-link">
                            <img
                                className="block h-full w-full object-contain"
                                src="/assets/images/logo.svg"
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <ul className="mt-16 gap-3 flex flex-col items-start justify-start w-full ">
                        {links.map((link) => (
                            <li key={link.path} className="w-full">
                                <NavLink title={link.label} to={link.path}
                                    className="nav-link text-[#AAA8BD] text-xs !w-full flex items-center py-2 px-4 rounded-lg !justify-start gap-4">
                                    <span className="block w-4 h-4">
                                        <link.icon />
                                    </span>
                                    <span className="text">{link.label}</span>
                                </NavLink>
                                {link.path === '/chat' && window.location.pathname.startsWith('/chat') && !sidebarState && (
                                    <>
                                        <button
                                            onClick={() => window.location.assign('/chat')}
                                            className="divide-y mt-4 divide-gray-200 py-2 text-purple-700 text-sm overflow-auto break-words pl-2 cursor-pointer"
                                        >
                                            <span>Neuer Chat</span>
                                        </button>
                                        <hr className="border-t w-full" />
                                    </>
                                )}
                                {link.path === '/chat' && !sidebarState && window.location.pathname.startsWith('/chat') && <HistoryComponent />}
                            </li>
                        ))}
                    </ul>
                    <div className="logout mt-auto w-full">
                        <div onClick={sidebarState ? toggleCollapse : undefined} className="w-full h-full flex flex-col items-start px-3 md:px-0">
                            <div className="flex justify-between items-center w-full px-1 hidden-when-sidebar-collapsed">
                                <p className="text-[#AAA8BD] text-[14px] font-medium leading-[21px] tracking-[1%]">{userTier}</p>
                                {!userData.productData.unlimited && showUpgradeButton && (
                                    <button 
                                        onClick={() => {
                                            manageStripeSubscription();
                                            setLoading(true);
                                        }}
                                        data-testid="upgrade-button3" 
                                        className="rounded-[4px] text-[11px] bg-secondary text-white hover:bg-secondary-600 py-1 px-2"
                                    >
                                    {loading ? <span className="flex items-center">Upgrade <div className="h-4 w-4 ml-1"><MiniSpinningLoader /></div></span> : 'Upgrade'}
                                    </button>
                                )}
                            </div>
                            <div className="flex flex-col w-full px-1 mb-1 mt-[6px] hidden-when-sidebar-collapsed">
                                <div className="relative w-full h-2 rounded-full bg-[#AAA8BD33]">
                                    <div
                                        className="absolute top-0 left-0 h-full bg-secondary rounded-full"
                                        style={{ width: `${(currentCreations / displayedCreations) * 100}%` }}
                                    ></div>
                                </div>
                                <div className="flex items-center text-[#AAA8BD] text-xs mt-2">
                                {!userData.userData.freetrial ? (
                                    <span className="text-[10px]">{userData.productData.unlimited ? "Unlimitierte Texte" : `${currentCreations} Texte`}</span>
                                ) : (
                                    <span className="text-[10px]">{currentCreations} Texte (neue Texte in {timeLeftColonFormat})</span>
                                )}
                                </div>
                            </div>
                            {/* User Info Section */}
                            <button onClick={sidebarState ? undefined : toggleCollapse} className="w-full flex items-center justify-between gap-3 py-4 px-1">
                                <ProfileIcon 
                                    showLetter={true}
                                    letter={firstLetter}
                                    className="w-8 h-8"
                                />
                                {/* Full Name */}
                                <p className="font-semibold text-xs text-gray-600 flex-grow break-words hidden-when-sidebar-collapsed transition-max-height">
                                    {fullName}
                                </p>
                                <div data-testid="settings-dropdown" className="hidden-when-sidebar-collapsed transition-max-height">
                                    {isOpen ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                                </div>
                            </button>

                            {/* Collapsible Links Section */}
                            <div
                                className={`w-full overflow-hidden transition-max-height duration-500 ease-out ${isOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}
                                style={{ transitionProperty: 'max-height, opacity' }}
                            >
                                <ul
                                    className={`gap-3 flex flex-col items-center w-full transition-transform duration-500 ease-out `}
                                >
                                    {links2.map((link) => (
                                        <li key={link.path} className="w-full" data-testid={`sidebar-link2-${link.label.toLowerCase()}`}>
                                            <NavLink
                                                title={link.label}
                                                to={link.path}
                                                className="nav-link text-[#AAA8BD] text-xs w-full flex items-center py-2 px-3 rounded-lg justify-start gap-4"
                                            >
                                                <span className="block w-4 h-4">
                                                    <link.icon />
                                                </span>
                                                <span className="text">{link.label}</span>
                                            </NavLink>
                                        </li>
                                    ))}

                                    {/* Sign Out Button */}
                                    <li className="w-full">
                                        <button
                                            data-testid="logout-button" 
                                            title="Sign out"
                                            onClick={Logout}
                                            className="nav-link text-[#AAA8BD] text-xs w-full flex items-center py-2 px-4 rounded-lg justify-start gap-4"
                                        >
                                            <span className="block w-4 h-4">
                                                <LogoutIcon />
                                            </span>
                                            <span className="text">Ausloggen</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
};

export default Sidebar;
